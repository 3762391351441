import React from 'react';
import imageOrdersEmpty from 'assets/img/orders-empty.svg';
import OrderItem from './orderItem';
import { FormattedMessage } from 'react-intl';
import 'pages/orders/styles.scss';

const OrdersList = ({ orders = [] }) => {
  const OrdersEmptyLayout = () => (
    <div className="text-center">
      <div>
        <img src={imageOrdersEmpty} alt="" />
      </div>
      <div className="text-big -gray-dark">
        <FormattedMessage id="ordersList.noActiveOrders" />
      </div>
    </div>
  );

  const OrdersList = ({ orders }) => orders.map((order) => {
    const {
      id, code, name, payment_status: status, delivery, stages, declaration_number,
    } = order;
    return (
      <OrderItem key={id + Math.random()} id={id} code={code} name={name} declarationNumber={declaration_number} payment_status={status} delivery={delivery} stages={stages} />
    );
  });

  if (!orders.length) {
    return <OrdersEmptyLayout />;
  }

  return (
    <div>
      <div className="order-header -list">
        <div className="order-code">
          <span className="label">
            <FormattedMessage id="ordersList.number" />
          </span>
        </div>
        <div className="order-name">
          <span className="label">
            <FormattedMessage id="ordersList.orderName" />
          </span>
        </div>
        <div className="order-status">
          <span className="label">
            <FormattedMessage id="ordersList.orderStatus" />
          </span>
        </div>
        <div className="order-payment">
          <span className="label">
            <FormattedMessage id="ordersList.paymentStatus" />
          </span>
        </div>
        <div className="order-delivery">
          <span className="label">
            <FormattedMessage id="ordersList.deliveryType" />
          </span>
        </div>
        <div className="order-date">
          <span className="label">
            <FormattedMessage id="ordersList.deliveryDate" />
          </span>
        </div>
      </div>
      <OrdersList orders={orders} />
    </div>
  );
};

export default OrdersList;
