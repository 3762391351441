import React from 'react';
import {toggleMobileMenu} from 'store/reducers/layout';
import {useDispatch, useSelector} from 'react-redux';

const HeaderMobile = ({ title }) => {
  const isOpenedMenu = useSelector(state => state.layoutStore.menuIsToggled);
  let mobileTitle = isOpenedMenu ? 'Меню' : title;
  const dispatch = useDispatch();
  const toggleMenu = () => {
    dispatch(toggleMobileMenu());
  }

  return (
    <div className="header-mobile-block mobile">
      <div className="header-mobile">
        <div className="mobile toggle-bar" onClick={toggleMenu}>
          <span className="icon-bar" />
        </div>
        <span className="mobile-title">{mobileTitle}</span>
      </div>
    </div>
  );
};

export default HeaderMobile;
