import React from "react";
import '../styles.scss';

const PhoneTag = (props) => {
    const {
        phoneNumber = '',
        deleteHandler = () => {},
    } = props;
    return (
        <div className="choose-item">
            {phoneNumber}
            <div className="close-button" onClick={() => deleteHandler(phoneNumber)}>
                <div className="close-button-icon"></div>
            </div>
        </div>
    )
}

export default PhoneTag;

