import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import InputMask from 'react-input-mask';
import Countdown, {zeroPad} from 'react-countdown';
import { TOKEN_KEY } from 'utils/const';
import RequestApi from 'services/RequestApi';
import LocalStorage from 'services/LocalStorage';
import Footer from 'components/footer';
import { FormattedMessage } from 'react-intl';
import logo from '../../assets/img/logo-white.png';
import { REACT_APP_API_URL_VERIFY_PHONE, REACT_APP_API_URL_VERIFY_CODE } from '../../config';
import './styles.scss';

const Login = () => {
  const [formData, setFormData] = useState({
    enterCode: false,
    codeUser: '',
    userPhone: '',
    phoneError: null,
    requestError: null,
    loading: false,
  });

  const history = useHistory();
  const [token, setToken] = useState('');
  const [expireCodeTime, setExpireCodeTime] = useState(null);

  const {
    enterCode,
    codeUser,
    userPhone,
    phoneError,
    requestError,
    loading,
  } = formData;

  const countDownTime = ({ minutes, seconds }) => (
    <span>
      {minutes}
      :
      {zeroPad(seconds, 2)}
    </span>
  );

  const checkPhone = (e) => {
    e.preventDefault();

    setFormData({ ...formData, requestError: false });
    const phone = userPhone.trim().replace(/[-+ \(\)]/g, '');
    if (phone.match(/^[0-9]{12}/g)) {
      setFormData({ ...formData, phoneError: false });
      requestUser();
      return;
    }

    setFormData({ ...formData, phoneError: true });
  };

  const requestUser = async () => {
    if (expireCodeTime) {
      return;
    }

    setFormData({ ...formData, loading: true });
    const response = await RequestApi.postData(REACT_APP_API_URL_VERIFY_PHONE, {
      phone: userPhone,
    });

    const { errors, verify_token: token, expires_in: expire } = response;

    if (errors) {
      setFormData({ ...formData, requestError: errors.phone, loading: false });
      return;
    }

    setFormData({
      ...formData, enterCode: true, requestError: null, loading: false,
    });
    setToken(token);
    setExpireCodeTime((Date.now() + expire * 1000));
  };

  const checkCode = async (e) => {
    e.preventDefault();

    setFormData({ ...formData, loading: true });

    const response = await RequestApi.postData(REACT_APP_API_URL_VERIFY_CODE, {
      code: codeUser,
      verify_token: token,
    });

    const { errors, access_token: accessToken } = response;

    if (errors) {
      setFormData({ ...formData, requestError: errors.code || errors.phone, loading: false });
      return;
    }

    LocalStorage.set(TOKEN_KEY, accessToken);
    history.push('/orders/all');
  };

  return (
    <div className="login-block text-center">
      <div className="login-logo">
        <a href="/login">
          <img src={logo} alt="" />
        </a>
      </div>
      {enterCode
        ? (
          <div className="login-content">
            <div className="text-big login-phone">{userPhone}</div>
            <a
              href="#"
              className="link-phone"
              onClick={(e) => {
                e.preventDefault();
                setFormData({ ...formData, enterCode: false });
                setExpireCodeTime(null);
                setToken(null);
              }}
            >
              <FormattedMessage id="login.changeNumber" />
            </a>
            <div className="login-confirm">
              <div>
                <FormattedMessage id="login.smsTitle"/>
              </div>
              <div className="login-counter">
                { expireCodeTime && (
                  <Countdown
                    date={expireCodeTime}
                    renderer={countDownTime}
                    onComplete={() => {
                      setExpireCodeTime(null);
                      setFormData({ ...formData, requestError: null });
                    }}
                  />
                ) }
              </div>
              <div className={classNames('login-repeat', { active: !expireCodeTime })} onClick={requestUser}>
                <FormattedMessage id="login.reSendLabel" />
              </div>
            </div>
            <form onSubmit={checkCode}>
              <div className="form-group">
                <label htmlFor="userPhone" className="form-label">
                  <FormattedMessage id="login.acceptCode" />
                </label>
                <input
                  name="userPhone"
                  className="form-control"
                  placeholder="XXXX"
                  type="tel"
                  autoComplete="off"
                  onChange={(e) => setFormData({ ...formData, codeUser: e.target.value, phoneError: false })}
                />
                { requestError && <div className="error">{requestError}</div> }
              </div>
              <div>
                <button className="btn" disabled={loading}>
                  <FormattedMessage id="login.continue" />
                </button>
              </div>
            </form>
          </div>
        )
        : (
          <div className="login-content">
            <form action="post" onSubmit={checkPhone}>
              <div className="form-group">
                <label htmlFor="userPhone" className="form-label">
                  <FormattedMessage id="login.phoneLabel" />
                </label>
                <InputMask
                  mask="+38 (999) 999-99-99"
                  maskChar=" "
                  name="userPhone"
                  className="form-control"
                  type="tel"
                  placeholder="+38 (000) 000-00-00"
                  onChange={(e) => setFormData({ ...formData, userPhone: e.target.value, phoneError: false })}
                />
                { phoneError && <div className="error"><FormattedMessage id="login.form.validationError"/></div> }
                { requestError && <div className="error">{requestError}</div> }
              </div>
              <div>
                <button className="btn" type="submit" disabled={loading}>
                  <FormattedMessage id="login.enter" />
                </button>
              </div>
            </form>
            <div className="login-info">
              <FormattedMessage id="login.description" />
            </div>
          </div>
        )}
      <Footer />
    </div>
  );
};

export default Login;
