import React from 'react';
import classNames from 'classnames';

import { useDispatch, useSelector } from 'react-redux';
import { setOrderSearch } from 'store/reducers/orders';
import { toggleSearch } from "../../store/reducers/layout";
import { FormattedMessage, useIntl } from 'react-intl';
import './search.scss';

const Search = () => {
  const dispatch = useDispatch();
  const searchOpen = useSelector(state => state.layoutStore.searchActive);
  const formattedMessage = useIntl().formatMessage;

  const onSearch = (e) => {
    dispatch(setOrderSearch(e.target.value));
  };

  const toggleSearchAction = () => {
    dispatch(toggleSearch());
  }

  return (
    <div className={classNames('search-wrapper', { 'search-open': searchOpen })}>
      <div className="search-mobile">
        <div className="header-mobile mobile">
          <span className="header-mobile-icon" onClick={toggleSearchAction} />
          <span className="mobile-title">
            <FormattedMessage id="search.title" />
          </span>
        </div>
        <div className="search-content">
          <div className="search-label mobile">
            <FormattedMessage id="search.orderNumberLabel" />
          </div>
          <div className="search-block flex">
            <input type="text" placeholder={formattedMessage({ id: 'search.button.placeholder' })} className="search-input" onChange={onSearch} />
            <button className="search-btn"><span className="icon-search" /></button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Search;
