import React, { useState, useEffect, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import Loader from 'components/loader';
import RequestApi from 'services/RequestApi';
import OrdersList from './ordersList';
import 'pages/orders/styles.scss';

const OrdersData = (props) => {
  const { url, searchTerm = '' } = props;
  const [orderList, setOrderList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [forcePage, setForcePage] = useState(0);

  const fetchOrders = useCallback(async (page) => {
    setForcePage(page.selected);
    const pageToGo = page.selected + 1;
    setLoading(true);
    const searchQuery = searchTerm ? `&term=${searchTerm}` : '';
    const ordersRes = await RequestApi.getData(`${url}?page=${pageToGo}&per_page=${process.env.REACT_APP_DATA_PER_PAGE}${searchQuery}`);
    const { orders, total } = ordersRes;

    if (!orders) {
      console.error('Can not get orders data');
      setLoading(false);
      return;
    }

    setOrderList(orders);
    setPageCount(Math.round(total / process.env.REACT_APP_DATA_PER_PAGE));
    setLoading(false);
  }, [url, searchTerm]);

  useEffect(() => {
    fetchOrders({ selected: forcePage });
  }, [url, searchTerm]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <OrdersList orders={orderList} />
      { pageCount > 1 && (
        <ReactPaginate
          previousLabel={'\<'}
          nextLabel={'\>'}
          breakLabel="..."
          breakClassName="break-me"
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={2}
          onPageChange={fetchOrders}
          containerClassName="pagination"
          subContainerClassName="pages pagination"
          pageLinkClassName="page-link"
          activeClassName="active"
          forcePage={forcePage}
        />
      ) }
    </>
  );
};

export default OrdersData;
