import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import Loader from "../../loader";

const DownloadButton = ({label, onClick})=>{
    const [loading, setLoading] = useState(false)
    const onButtonClick = async ()=>{
        setLoading(true);
        await onClick();
        setLoading(false);
    }

    return (
        <button  className={'statistic-download-btn btn'} onClick={onButtonClick} disabled={loading}>
            {loading ? (
                <Loader size={'small'}/>
            ) : (
                <FormattedMessage id={label} />
            )}
        </button>
    )
}

export default DownloadButton