import React from 'react';
import './styles.scss';

const Loader = ({ size }) => (
  <div className={`loader-wrapper ${size}`}>
    <div className="loader" />
  </div>
);

export default Loader;
