import { MONTHS, STATUS_ALIAS_BAR_WIDTH } from 'utils/const';
import React from "react";

export const getDateSeparatly = (date) => {
  const orderDate = new Date(date);
  const orderYear = orderDate.getFullYear();
  const orderDay = orderDate.getDate();
  const orderMonthNumber = orderDate.getMonth() + 1;
  const orderHour = orderDate.getHours();
  const orderMinutes = orderDate.getMinutes().toString().length === 2 ? orderDate.getMinutes() : '0' + orderDate.getMinutes();
  const orderMonth = MONTHS[orderMonthNumber - 1];

  return {
    year: orderYear,
    day: orderDay,
    month: orderMonth,
    monthNumber: orderMonthNumber,
    hour: orderHour,
    minutes: orderMinutes,
  };
};

export const getFormattedTime = (date) => {
  const splitedDate = getDateSeparatly(date);
  return `${splitedDate.hour}:${splitedDate.minutes}`;
};

export const getFormattedDate = (date) => {
  const splitedDate = getDateSeparatly(date);
  return `${addZeroToNumber(splitedDate.day)}.${addZeroToNumber(
    splitedDate.monthNumber,
  )}.${splitedDate.year}`;
};

export const getFormattedDateForDatepicker = (date, firstTime = false) => {
  const splitedDate = getDateSeparatly(date);
  return `${splitedDate.year}-${addZeroToNumber(splitedDate.monthNumber)}-${
    firstTime ? '01' : addZeroToNumber(splitedDate.day)
  }`;
};

export const addZeroToNumber = (number) => {
  if (number >= 10) {
    return number;
  }

  return `${0}${number}`;
};

export const getFormattedDateShort = (dateToFormat,withTime = false )=>{
  const date = new Date(dateToFormat);
  const min = date.getMinutes().toString().padStart(2, '0');
  const hour = date.getHours().toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().slice(-2);
  const allowTime =  withTime ? `${hour}:${min}` : '';
  const formattedDate = `${day}.${month}.${year} ${allowTime}`;

  return formattedDate;
}

export const getDateFormatted = (date, withTime = false) => {
  if (date === null) {
    return null;
  }
  const orderDate = getDateSeparatly(date);
  const {
    day, month, hour, minutes,
  } = orderDate;

  const additionalTime = withTime ? `${hour}:${minutes}` : '';
  return `${day} ${month} ${additionalTime}`;
};

export const getOrderStatus = (stages) => {
  let status = {};

  stages.forEach((item, index) => {
    if (!item.current) {
      return;
    }

    status = stages[index];
  });

  return status;
};

export const getStatusBarWidth = (statusAlias) => STATUS_ALIAS_BAR_WIDTH[statusAlias] || 0;

export const isMobile = () => {
  const regexp = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|iPad Simulator|iPhone Simulator|iPod Simulator/i;
  return regexp.test(navigator.userAgent) || (navigator.userAgent.includes("Mac") && "ontouchend" in document);
}

export const findAndReplaceLink = (inputText) => {
  if (typeof inputText !== 'string') {
    return '';
  }
  const URL_REGEX = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
  return inputText.split(/\s/)
      .map(part => URL_REGEX.test(part) ? `<a className="link-phone" target="_blank" href="${part}">${part}</a> ` : part + " ")
      .join('');
}
