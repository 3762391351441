import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const Footer = ({ show }) => (
  <footer className="footer">
    <div className="container">
      <div className="flex">
        <div className="aside-block">
          <FormattedMessage id="footer.rights" />
        </div>
        <div className="footer-bar">
          <div>
            {!!show && (
              <a href="/terms">
                <FormattedMessage id="footer.terms" />
              </a>
            )}
          </div>
          <div className="copyright">
            <FormattedMessage id="footer.developedIn" />
            {' '}
            <a href="https://www.webspark.com/">WEBSPARK</a>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
