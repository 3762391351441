import React, { useEffect } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import ListLinks from 'components/account/list-links';
import RequestApi from 'services/RequestApi';
import Contact from 'components/account/contact';
import Devices from 'components/account/devices';
import Loader from 'components/loader';
import { FormattedMessage } from 'react-intl';
import HeaderMobile from 'components/header/parts/headerMobile';
import { REACT_APP_API_URL_ACCOUNT } from '../../config';
import Barcode from '../../components/barcode';
import './styles.scss';

const Account = () => {
  const [userInfo, setUserInfo] = React.useState(null);

  useEffect(() => {
    if (userInfo) {
      return;
    }

    (async function () {
      const { card } = await RequestApi.getData(`${REACT_APP_API_URL_ACCOUNT}/card`);
      setUserInfo(card);
    }());
  }, []);

  const listDocuments = [
    {
      id: 1,
      title: 'Договор №38483',
      link: '#',
    },
    {
      id: 2,
      title: 'Подписанное соглашение',
      link: '#',
    },
    {
      id: 3,
      title: 'План сотрудничества',
      link: '#',
    },
  ];

  const listPermits = [
    {
      id: 1,
      title: 'Пропуск до 12.08',
      link: '#',
    },
    {
      id: 2,
      title: 'Пропуск до 11.08',
      link: '#',
    },
  ];

  if (userInfo) {
    return (
      <div>
        <HeaderMobile title="Аккаунт" />
        <Tabs selectedTabClassName="active">
          <div className="tabs-wrapper">
            <TabList className="tabs-list">
              <Tab className="tabs-item tabs-link">
                К
                <span className="desktop">арта</span>
                <span className="desktop"> к</span>
                лієнт
                <span className="desktop">а</span>
              </Tab>
              <Tab className="tabs-item tabs-link">
                <FormattedMessage id="account.tabs.devices" />
              </Tab>
              <Tab className="tabs-item tabs-link">
                <FormattedMessage id="account.tabs.contacts" />
              </Tab>
              <Tab className="tabs-item tabs-link desktop">
                <FormattedMessage id="account.tabs.tour" />
              </Tab>
            </TabList>
          </div>

          <TabPanel>
            <div className="flex account-content">
              <div className="account-col">
                <div className="form-group">
                  <div className="label">
                    <FormattedMessage id="account.fullnameLabel" />
                  </div>
                  <div className="text-big">{userInfo.name}</div>
                </div>
                <div className="form-group">
                  <div className="label">
                    <FormattedMessage id="account.phoneNumberLabel" />
                  </div>
                  <div className="text-big">{userInfo.phone}</div>
                </div>
                <div className="form-group">
                  <div className="label">
                    <FormattedMessage id="account.companyLabel" />
                  </div>
                  <div className="text-big">
                    {userInfo.companies && userInfo.companies.length &&
                        userInfo.companies.map((item) => (
                          <span className="account-company" key={item}>{item}</span>
                        ))
                      }
                  </div>
                </div>
                <div className="bar-code">
                  {userInfo && <Barcode userInfo={userInfo}/>}
                </div>
              </div>
              <div className="account-col" style={{opacity: 0}}>
                <div className="label">
                  <FormattedMessage id="account.documentsLabel" />
                </div>
                <ListLinks list={listDocuments} />
                <div className="label">
                  <FormattedMessage id="account.passLabel" />
                </div>
                <ListLinks list={listPermits} />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <Devices />
          </TabPanel>
          <TabPanel>
            <div className="flex account-content">
              <div className="account-col account-col-40">
                <div className="form-group">
                  <div className="label">
                    <FormattedMessage id="account.phoneNumberLabel" />
                  </div>
                  <a href="tel:+380577334190" className="link-phone text-big">+38 (057) 733-41-90</a>
                </div>
                <div className="form-group">
                  <div className="label">
                    <FormattedMessage id="account.addressLabel" />
                  </div>
                  <div className="text-big">
                    <FormattedMessage id="account.address" />
                  </div>
                </div>
                <div>
                  <Contact />
                </div>

                <Tabs selectedTabClassName="active">
                  <div className="tabs-wrapper mobile">
                    <TabList className="tabs-list">
                      <Tab className="tabs-item tabs-link">
                        <FormattedMessage id="account.mapLabel" />
                      </Tab>
                      <Tab className="tabs-item tabs-link">
                        <FormattedMessage id="account.tabs.tour" />
                      </Tab>
                    </TabList>
                  </div>
                  <TabPanel>
                    <div className="flex account-content">
                      <div className="account-col account-col-60 mobile">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.2232889250204!2d36.23685281606006!3d49.96967097941193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a0638c4dad49%3A0xdad7c8d3e0363a33!2z0KjQotCQ0J3Qpi3QotCV0KXQndCe0JvQntCT0JjQrywg0J7QntCe!5e0!3m2!1sen!2sua!4v1607934172628!5m2!1sen!2sua"
                            width="100%"
                            height="300"
                            frameBorder="0"
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                        />
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <div className="account-content -no-side-margin">
                      <iframe
                          src="https://www.google.com/maps/embed?pb=!4v1608017350689!6m8!1m7!1sCAoSLEFGMVFpcE52dzRWOS1vRjlCOF9NbHM5eExHbGFRd21CMkItb0xUa2pteU9K!2m2!1d49.9696188019791!2d36.23898300992433!3f245.4830519892396!4f-8.320916691111407!5f0.7820865974627469"
                          width="100%"
                          height="450"
                          frameBorder="0"
                          allowFullScreen=""
                          aria-hidden="false"
                          tabIndex="0"
                      />
                    </div>
                  </TabPanel>
                </Tabs>
              </div>
              <div className="account-col account-col-60 desktop">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2566.2232889250204!2d36.23685281606006!3d49.96967097941193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4127a0638c4dad49%3A0xdad7c8d3e0363a33!2z0KjQotCQ0J3Qpi3QotCV0KXQndCe0JvQntCT0JjQrywg0J7QntCe!5e0!3m2!1sen!2sua!4v1607934172628!5m2!1sen!2sua"
                  width="100%"
                  height="300"
                  frameBorder="0"
                  allowFullScreen=""
                  aria-hidden="false"
                  tabIndex="0"
                />
              </div>
            </div>
          </TabPanel>
          <TabPanel>
            <div className="account-content -no-side-margin">
              <iframe
                src="https://www.google.com/maps/embed?pb=!4v1608017350689!6m8!1m7!1sCAoSLEFGMVFpcE52dzRWOS1vRjlCOF9NbHM5eExHbGFRd21CMkItb0xUa2pteU9K!2m2!1d49.9696188019791!2d36.23898300992433!3f245.4830519892396!4f-8.320916691111407!5f0.7820865974627469"
                width="100%"
                height="450"
                frameBorder="0"
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              />
            </div>
          </TabPanel>
        </Tabs>

      </div>
    );
  }
  return <Loader />;
};

export default Account;
