export const SET_ORDER_SEARCH = 'SET_ORDER_SEARCH';

export const setOrderSearch = (search) => ({
  type: SET_ORDER_SEARCH,
  search,
});

const initialState = {
  search: '',
};

const ordersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ORDER_SEARCH:
      return { ...state, search: action.search };
    default: return state;
  }
};

export default ordersReducer;
