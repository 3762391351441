export const SET_USER_DATA = 'SET_USER_PROFILE';

export const setUserData = (data) => ({
  type: SET_USER_DATA,
  data,
});

const initialState = {
  userData: null,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return { ...state, userData: action.data };
    default: return state;
  }
};

export default userReducer;
