import axios from 'axios';
import { TOKEN_KEY } from 'utils/const';
import LocalStorage from 'services/LocalStorage';

class RequestApi {
  static getHeaders(postRequest = false, customHeaders={}) {
    const token = LocalStorage.get(TOKEN_KEY);
    const headers = {
      Accept: 'application/json',
      'X-APP-TYPE': 'web',
      'X-APP-VERSION': process.env.REACT_APP_VERSION,
      ...customHeaders
    };

    if (postRequest) {
      headers['Content-type'] = 'application/json';
    }

    if (token) {
      headers.Authorization = `Bearer ${token}`;
      return headers;
    }

    return headers;
  }

  static async postData(url, body, options, method = 'post') {
    let response = null;

    const optionsRequest = {
      headers: RequestApi.getHeaders(true),
      ...options,
    };

    const args = [url];

    if (method === 'delete') {
      args.push(optionsRequest);
    } else {
      args.push(...[body, optionsRequest]);
    }

    try {
      response = await axios[method](...args);
    } catch (error) {
      const { data } = error.response;

      if (data && data.data && data.data.errors) {
        return { errors: data.data.errors };
      }

      return {
        errors: [{
          message: data.message,
        }],
      };
    }

    const { data: { data, message } } = response;

    return data || {message} || null;
  }

  static async getData(url, options = {}) {
    let response = null;
    try {
      response = await axios.get(url, {
        headers: RequestApi.getHeaders(),
        ...options,
      });
    } catch (error) {
      const { data: { errors } } = error.response;
      if (errors) {
        return { errors };
      }

      return {
        errors: [{
          message: error.message,
        }],
      };
    }

    const { data: { data } } = response;

    return data || null;
  }

  static async getFileData(url, options = {}, customHeaders={}) {
    let response = null;
    try {
      response = await axios.get(url, {
        headers: RequestApi.getHeaders(false, customHeaders),
        ...options,
      });
    } catch (error) {
      const { data: { errors } } = error.response;
      if (errors) {
        return { errors };
      }

      return {
        errors: [{
          message: error.message,
        }],
      };
    }

    return response || null;
  }
}

export default RequestApi;
