import React from 'react';
import { NavLink } from 'react-router-dom';
import imageCatalogEmpty from 'assets/img/catalog-empty.svg';
import { FormattedMessage } from 'react-intl';
import HeaderMobile from 'components/header/parts/headerMobile';
import './catalog.scss';

const Catalog = () => (
  <>
    <HeaderMobile title="Каталог" />
    <div className="page-title desktop">
      <div className="text-bolder text-dark">
        <FormattedMessage id="catalog.title" />
      </div>
    </div>
    <div className="text-center">
      <div className="mobile-catalog-image">
        <img src={imageCatalogEmpty} alt="" />
      </div>
      <div className="mobile-catalog-block">
        <div className="form-group text-big text-dark">
          <FormattedMessage id="catalog.indev" />
        </div>
        <div>
          <NavLink className="btn" to="/orders/all">
            <FormattedMessage id="catalog.backToOrders" />
          </NavLink>
        </div>
      </div>
    </div>
  </>
);

export default Catalog;
