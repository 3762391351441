import React, { useState, useEffect } from 'react';
import SlideToggle from 'react-slide-toggle';
import { Modal } from 'react-responsive-modal';
import { MODAL_CLOSE_ICON } from 'utils/const';
import { getDateFormatted } from 'utils/helpers';
import Loader from 'components/loader';
import RequestApi from 'services/RequestApi';
import { FormattedMessage } from 'react-intl';
import { REACT_APP_API_URL_ACCOUNT } from '../../../config';
import './styles.scss';

const Devices = () => {
  const [deviceList, setDeviceList] = useState(null);
  const [deviceActive, setDeviceActive] = useState(0);
  const [modalDeviceRemove, setOpenDeviceRemove] = useState(false);

  useEffect(() => {
    fetchDevices();
  }, []);

  const fetchDevices = async () => {
    const resDevices = await RequestApi.getData(`${REACT_APP_API_URL_ACCOUNT}/devices`);
    setDeviceList(resDevices.devices);
  };

  const removeDevice = async () => {
    await RequestApi.postData(`${REACT_APP_API_URL_ACCOUNT}/devices/${deviceActive}`, {}, {}, 'delete');
    await fetchDevices();
    setOpenDeviceRemove(false);
  };

  return (
    <div className="device-list">
      {
            deviceList ? deviceList.map(({
              id, ip_address, platform, location, created_at,
            }) => (

              <SlideToggle
                key={id}
                collapsed
                render={({ toggle, setCollapsibleElement, toggleState }) => (
                  <div className="device-item">
                    <div
                      className={`device-header ${
                        (toggleState || '').toLowerCase()}`}
                      onClick={toggle}
                    >
                      <div className="device-platform">
                        <span className={`device-icon icon-${platform.toLowerCase()}`} />
                        {platform !== '' ? platform : (<FormattedMessage id="devices.unknownDevice" />)}
                      </div>
                      <div className="device-ip">{ip_address}</div>
                      <div className="device-location">{location}</div>
                      <div className="device-date">
                        {getDateFormatted(created_at)}
                      </div>
                    </div>
                    <div className="device-content" ref={setCollapsibleElement}>
                      <div className="form-group">
                        <span className="">
                          <FormattedMessage id="devices.firstDeviceEnter" />
                        </span>
                        <span className="value">
                          {getDateFormatted(created_at)}
                        </span>
                      </div>
                      <div className="form-group">
                        <span className="">
                          <FormattedMessage id="devices.recentActions" />
                        </span>
                      </div>
                      <div className="form-group">
                        <span className="dot" />
                        <span className="value">
                          {location}
                          {' '}
                        </span>
                        <span className="label -inline -big">
                          {getDateFormatted(created_at)}
                        </span>
                      </div>

                      <div className="text-center">
                        <span className="link value -danger" onClick={() => { setDeviceActive(id); setOpenDeviceRemove(true); }}>
                          <FormattedMessage id="devices.leaveDevice" />
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              />
            ))
              : (
                <div className="text-center w-50">
                  <Loader />
                </div>
              )
          }
      <Modal
        open={modalDeviceRemove}
        onClose={() => setOpenDeviceRemove(false)}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="devices.modal.title" />
        </div>
        <div>
          <div className="text-center">
            <span className="btn btn-simple" onClick={() => { setDeviceActive(0); setOpenDeviceRemove(false); }}>
              <FormattedMessage id="devices.modal.decline" />
            </span>
            <span className="btn btn-danger" onClick={() => removeDevice()}>
              <FormattedMessage id="devices.modal.accept"/>
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Devices;
