import React from 'react';
import { useBarcode } from 'react-barcodes';

const Barcode = ({ userInfo }) => {
  const { inputRef } = useBarcode({
    value: userInfo.barcode.value,
    options: {
      displayValue: false,
      width: 3.5,
      height: 50,
      format: userInfo.barcode.format,
      background: userInfo.barcode.background,
      lineColor: userInfo.barcode.line_color,
    }
  });

  return <img ref={inputRef} alt=""/>;
}

export default Barcode;