import LocalStorage from '../../services/LocalStorage';
import { NOTIFICATION_COUNTER } from '../../utils/const';

export const INCREASE_NOTIFICATION_COUNTER = 'INCREASE_NOTIFICATION_COUNTER';
export const SET_NOTIFICATION_COUNTER = 'SET_NOTIFICATION_COUNTER';
export const SET_IS_SHOW_NOTIFICATION_POPUP = 'SET_IS_SHOW_NOTIFICATION_POPUP';

export const increaseNotificationCounter = (notificationCounter) => ({
  type: INCREASE_NOTIFICATION_COUNTER,
  notificationCounter,
});

export const setNotificationCounter = (notificationCounter) => ({
  type: SET_NOTIFICATION_COUNTER,
  notificationCounter,
});

export const setIsShowNotificationPopup = (show) => ({
  type: SET_IS_SHOW_NOTIFICATION_POPUP,
  show,
});

const initialState = {
  notificationCounter: 0,
  show: true,
};

const notificationCounterReducer = (state = initialState, action) => {
  let newNotificationCounterValue = 0;
  switch (action.type) {
    case INCREASE_NOTIFICATION_COUNTER:
      newNotificationCounterValue = +state.notificationCounter + 1
      LocalStorage.set(NOTIFICATION_COUNTER, newNotificationCounterValue);
      return { ...state, notificationCounter: newNotificationCounterValue };
    case SET_NOTIFICATION_COUNTER:
      newNotificationCounterValue = +state.notificationCounter + 1
      LocalStorage.set(NOTIFICATION_COUNTER, newNotificationCounterValue);
      return { ...state, notificationCounter: action.notificationCounter };
    case SET_IS_SHOW_NOTIFICATION_POPUP:
      return { ...state, show: action.show };
    default: return state;
  }
};

export default notificationCounterReducer;
