import React, { useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { MODAL_CLOSE_ICON } from 'utils/const';
import Errors from 'components/errors';
import RequestApi from 'services/RequestApi';
import imageContactSuccess from 'assets/img/contact-success.svg';
import { REACT_APP_API_URL_FEEDBACK } from '../../../config';
import { FormattedMessage, useIntl } from 'react-intl';

const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    message: '',
  });

  const formattedMessage = useIntl().formatMessage;

  const [error, setError] = useState(null);
  const [activePopupName, setActivePopupName] = useState('');

  const handleModalContactSubmit = async () => {
    const { email, message } = formData;
    const resContact = await RequestApi.postData(REACT_APP_API_URL_FEEDBACK, JSON.stringify({
      email,
      message,
    }));

    if (!resContact) {
      setActivePopupName('success');
      setFormData({
        email: '',
        message: '',
      });
      setError(null);
      return;
    }

    const { errors = null } = resContact;

    if (!errors) {
      return;
    }

    setError(errors);
  };

  const onCancelFeedback = () => {
    const { email, message } = formData;
    if (email.trim() !== '' || message.trim() !== '') {
      setActivePopupName('delete');
      return;
    }

    setActivePopupName('');
    setFormData({
      email: '',
      message: '',
    });
  };

  const onInput = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <div>
        <span className="btn btn-outline -full" onClick={() => setActivePopupName('feedback')}>
          <FormattedMessage id="contact.feedback" />
        </span>
      </div>
      <Modal
        open={activePopupName === 'feedback'}
        onClose={onCancelFeedback}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="contact.modal.feedback.title"/>
        </div>
        <form action="">
          <div className="form-group">
            <div className="form-label">Email</div>
            <input className="form-control" placeholder="example@mail.com" value={formData.email} name="email" onChange={onInput} />
          </div>
          <div className="form-group">
            <div className="form-label">
              <FormattedMessage id="contact.modal.feedback.message" />
            </div>
            <textarea
              className="form-control"
              placeholder={formattedMessage({ id: 'contact.modal.feedback.textarea.placeholder' })}
              name="message"
              value={formData.message}
              rows="4"
              onChange={onInput}
            />
            { error && <Errors errors={error} /> }
          </div>
          <div className="text-center">
            <span className="btn btn-simple -success" onClick={onCancelFeedback}>
              <FormattedMessage id="contact.modal.feedback.decline" />
            </span>
            <span className="btn" onClick={handleModalContactSubmit}>
              <FormattedMessage id="contact.modal.feedback.accept" />
            </span>
          </div>
        </form>
      </Modal>
      <Modal
        open={activePopupName === 'success'}
        onClose={() => setActivePopupName('')}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="contact.modal.success.title" />
        </div>
        <div className="text-center">
          <img src={imageContactSuccess} alt="" />
        </div>
        <div className="modal-title -sub">
          <FormattedMessage id="contact.modal.success.secondTitle" />
        </div>
        <div className="form-group text-center">
          <div className="label">
            <FormattedMessage id="contact.modal.success.text"/>
          </div>
        </div>
        <div className="text-center">
          <span className="btn" onClick={() => setActivePopupName('')}>
            <FormattedMessage id="contact.modal.success.close"/>
          </span>
        </div>
      </Modal>
      <Modal
        open={activePopupName === 'delete'}
        onClose={() => setActivePopupName('')}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="contact.modal.delete.title" />
        </div>
        <div>
          <div className="text-center">
            <span className="btn btn-simple" onClick={() => setActivePopupName('feedback')}>
              <FormattedMessage id="contact.modal.delete.decline" />
            </span>
            <span
              className="btn btn-danger"
              onClick={() => {
                setActivePopupName('');
                setFormData({ email: '', message: '' });
              }}
            >
              <FormattedMessage id="contact.modal.delete.delete" />
            </span>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Contact;
