import React, { useState, useEffect } from 'react';
import HeaderMobile from 'components/header/parts/headerMobile';
import { FormattedMessage, useIntl } from 'react-intl';
import RequestApi from '../../services/RequestApi';
import { REACT_APP_API_URL_HELP } from '../../config';
import 'pages/statistic/styles.scss';


const InfoList = () => {
  const formattedMessage = useIntl().formatMessage;
  const [links, setLinks] = useState([]);

  useEffect(() => {
    if (!links.length) {
      (async () => {
        const res = await RequestApi.getData(REACT_APP_API_URL_HELP);
        setLinks(res.links);
      })();
    }
  }, []);

  const generateLinks = (links) => {
    return links.map((link) => (
      <li>
        <a href={link.url} target='_blank' className="link-phone">{link.text}</a>
      </li>
    ));
  }

  return (
    <>
      <HeaderMobile title={formattedMessage({ id: 'info.title' })} />
      <div className="desktop page-title text-bolder">
          <FormattedMessage id="info.title" />
      </div>
      {links.length
        ? <ul className="info-list">
            {generateLinks(links)}
          </ul>
        : (<h3><FormattedMessage id="info.noLinks"/></h3>)
      }
    </>
  );
};

export default InfoList;
