import React, { useEffect, useState} from 'react';

import InfoList from '../../components/info/info';
import './styles.scss';

const Info = () => {

  return (
    <>
      <InfoList/>
    </>
  );
};

export default Info;
