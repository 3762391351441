import React, { useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setUserData } from 'store/reducers/user';
import { toggleMobileMenu, toggleSearch } from 'store/reducers/layout';
import { TOKEN_KEY } from 'utils/const';
import RequestApi from 'services/RequestApi';
import LocalStorage from 'services/LocalStorage';
import HeaderLogin from './parts/headerLogin';
import HeaderDebt from './parts/headerDebt';
import { FormattedMessage } from 'react-intl';
import { REACT_APP_API_URL_ACCOUNT } from '../../config';
import './styles.scss';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    (async function () {
      const userResponse = await RequestApi.getData(REACT_APP_API_URL_ACCOUNT);
      if (userResponse.errors) {
        LocalStorage.remove(TOKEN_KEY);
        history.push('/login');
        return;
      }

      dispatch(setUserData(userResponse));
    }());
  }, []);

  const toggleMenu = () => {
    dispatch(toggleMobileMenu());
  }

  const toggleSearchAction = () => {
    dispatch(toggleSearch());
  }

  return (
    <header className="header">
      <div className="container">
        <div className="flex">
          <div className="aside-block">
            <Link to="/orders/all">
              <img src="/logo.png" className="logo" alt="logo" />
            </Link>
          </div>
          <div className="header-content">
            <div className="header-content-mobile mobile">
              <div className="mobile toggle-bar" onClick={toggleMenu}>
                <span className="icon-bar" />
              </div>
              <span className="mobile-title">
                <FormattedMessage id="header.title" />
              </span>
              <div className="mobile search-btn" onClick={toggleSearchAction}>
                <span className="icon-search" />
              </div>
            </div>
            <div className="desktop w-50">
              <HeaderDebt />
            </div>
            <div className="desktop">
              <HeaderLogin />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
