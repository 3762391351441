import React from "react";
import '../styles.scss';

const PhoneItem = (props) => {
    const {
        phoneNumber = '',
        chooseHandler = () => {}
    } = props;
    return (
        <div className="phone-choose-item" onClick={() => chooseHandler(phoneNumber)}>
            {phoneNumber}
        </div>
    )
}

export default PhoneItem;
