import React from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import './styles.scss';
import {FormattedMessage} from "react-intl";

const Tabs = () => {
  const match = useRouteMatch();
  return (
    <div className="tabs-wrapper">
      <ul className="tabs-list tabs-list-justify">
        <li className="tabs-item">
          <NavLink to={`${match.url}/all`} activeClassName="active" className="tabs-link">
            <FormattedMessage id="orders.tabs.one"/>
            {' '}
            <span className="desktop">
              <FormattedMessage id="orders.tabs.two"/>
            </span>
          </NavLink>
        </li>
        <li className="tabs-item">
          <NavLink to={`${match.url}/my`} activeClassName="active" className="tabs-link"><FormattedMessage id="orders.tabs.three" /></NavLink>
        </li>
        <li className="tabs-item">
          <NavLink to={`${match.url}/them`} activeClassName="active" className="tabs-link"><FormattedMessage id="orders.tabs.four" /></NavLink>
        </li>
        <li className="tabs-item">
          <NavLink to={`${match.url}/archive`} activeClassName="active" className="tabs-link"><FormattedMessage id="orders.tabs.five" /></NavLink>
        </li>
      </ul>
    </div>
  );
};

export default Tabs;
