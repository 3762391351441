import React, {useEffect, useTransition} from 'react';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import HeaderMobile from 'components/header/parts/headerMobile';
import { FormattedMessage, useIntl } from 'react-intl';
import RequestApi from 'services/RequestApi';
import {getFormattedTime, getFormattedDate} from 'utils/helpers';
import 'pages/statistic/styles.scss';
import 'react-responsive-modal/styles.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {REACT_APP_API_URL_STATISTIC, REACT_APP_API_URL_STATISTIC_ANALITICS} from "../../config";
import DownloadButton from "./downloadButton";

const days = ['Нд', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']

const months = [
  'Січень',
  'Лютий',
  'Березень',
  'Квітень',
  'Травень',
  'Червень',
  'Липень',
  'Серпень',
  'Вересень',
  'Жовтень',
  'Листопад',
  'Грудень'
]

const locale = {
  localize: {
    day: n => days[n],
    month: n => months[n]
  },
  formatLong: {
    date: () => 'mm/dd/yyyy'
  }
}

const Statistics = ({ statistic, onChangeStartDate, onChangeEndDate, startDate, endDate, statisticTotal, currentPage }) => {
  const formattedMessage = useIntl().formatMessage;
  const perPage = process.env.REACT_APP_DATA_PER_PAGE
  const StatisticEmptyLayout = () => (
    <div className="text-center">
      <div className="text-big -gray-dark">
        <FormattedMessage id="statistic.emptyLabel" />
      </div>
    </div>
  );

  useEffect(() => {
    const datePickers = document.getElementsByClassName('react-datepicker__input-container');
    Array.from(datePickers).forEach((el => el.childNodes[0].setAttribute('readOnly', true)))
  });

  const convertCsvToJson = async (csvString)=> {
      return new Promise((resolve, reject) => {
          Papa.parse(csvString, {
              header: true,
              dynamicTyping: true,
              complete: (result) => {
                  resolve(result.data);
              },
              error: (error) => {
                  reject(error.message);
              }
          });
      });
  }

  const downloadxls = (data, filename='sheetjs') => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
      XLSX.writeFile(wb, `${filename}.xlsx`);
  };

  const onDownloadClick = (path, loadedFilename)=>{
    RequestApi.getFileData(`${path}`,
        {}, {Accept: 'text/csv'}
    ).then(async (res)=>{
        const jsonData   = await convertCsvToJson(res.data);
        downloadxls(jsonData, loadedFilename);
    }).catch((error) => {
        console.log('error', error)
    });
  }

  const statisticDownload = ()=>{
      onDownloadClick(`${REACT_APP_API_URL_STATISTIC}?from=${startDate}&to=${endDate}`, 'Analytics')
  }

  const allUserDownload = ()=>{
      onDownloadClick(`${REACT_APP_API_URL_STATISTIC_ANALITICS}?from=${startDate}&to=${endDate}`, 'Analytics_Wis_Out_Users')
  }
  const StatisticLayout = ({ statistic }) => (
      <>
        <ul className="statistic-list">
          {
            statistic.map(({name, companies, latest_login, logins_count,}, index) => {
              const latestLoginDate = new Date(latest_login);
              return (
                <li className="statistic-item" key={index}>
                  <div className="statistic-company-number">
                    <span className="label">
                      {index + 1 + (currentPage * perPage - perPage)}
                    </span>
                  </div>
                    <span className="statistic-company-name">
                        <span className="label mobile">
                            <FormattedMessage id="statistic.companyNameLabel" />
                        </span>
                        {companies.join(',')}
                    </span>
                    <span className="statistic-fullname">
                        <span className="label mobile">
                        <FormattedMessage id="statistic.fullNameLabel" />
                        </span>
                        {name}
                    </span>
                    <span className="statistic-last-enter">
                        <span className="label mobile">
                            <FormattedMessage id="statistic.lastEnterLabel" />
                        </span>
                        {getFormattedTime(latestLoginDate)} {getFormattedDate(latestLoginDate)}
                    </span>
                    <span className="statistic-counts">
                        <span className="label mobile">
                            <FormattedMessage id="statistic.countNumberOfEntersLabel" />
                        </span>
                        {logins_count}
                    </span>
                </li>
              );
            })
          }
        </ul>
        <ul className={'statistic-list-total'}>
          <li>
              <FormattedMessage id="statistic.loginsForPeriod" />: <span>{statisticTotal?.loginsForPeriod || 0}</span>
          </li>
          <li>
              <FormattedMessage id="statistic.registrationForPeriod" />: <span> {statisticTotal?.registrationForPeriod || 0} </span>
          </li>
          <li>
              <FormattedMessage id="statistic.totalRegistered" />: <span> {statisticTotal?.totalRegistered || 0} </span>
          </li>
        </ul>
          <div className={'statistic-list-download'}>
              <DownloadButton onClick={statisticDownload} label={'statistic.btnAll'}/>
              <DownloadButton onClick={allUserDownload} label={'statistic.bntStat'}/>
          </div>
      </>

  );

  return (
    <>
      <div className="statistic-top">
        <HeaderMobile title={formattedMessage({ id: 'statistic.title' })} />
        <span className="desktop text-bolder">
          <FormattedMessage id="statistic.title" />
        </span>
        <div className="statistic-date-block">
          <div className="statistic-date-container">
            <span className="statistic-show-period-label">
              <FormattedMessage id="statistic.showPeriodLabel" />
            </span>
            <div className="statistic-date-element">
              <span className="text-bolder"><FormattedMessage id="statistic.fromDate" /></span>
              <DatePicker
                locale={locale}
                className='statistic-datepicker'
                dateFormat="yyyy-MM-dd"
                selected={new Date(startDate)}
                utc={false}
                onChange={date => onChangeStartDate(date)}/>
            </div>
            <div className="statistic-date-element">
              <span className="text-bolder"><FormattedMessage id="statistic.toDate" /></span>
              <DatePicker
                locale={locale}
                className='statistic-datepicker'
                popperPlacement="bottom-end"
                dateFormat="yyyy-MM-dd"
                selected={new Date(endDate)}
                onChange={date => onChangeEndDate(date)}/>
            </div>
          </div>
        </div>
      </div>
      {
        statistic.length
          ? (
            <div>
              <div className="statistic-header desktop">
                <div className="statistic-company-number">
                  <span className="label">
                    №
                  </span>
                </div>
                <div className="statistic-company-name">
                  <span className="label">
                    <FormattedMessage id="statistic.companyNameLabel" />
                  </span>
                </div>
                <div className="statistic-fullname">
                  <span className="label">
                    <FormattedMessage id="statistic.fullNameLabel" />
                  </span>
                </div>
                <div className="statistic-last-enter">
                  <span className="label">
                    <FormattedMessage id="statistic.lastEnterLabel" />
                  </span>
                </div>
                <div className="statistic-counts">
                  <span className="label">
                    <FormattedMessage id="statistic.countNumberOfEntersLabelShort" />
                  </span>
                </div>
              </div>
              <StatisticLayout statistic={statistic} />
            </div>
          )
          : <StatisticEmptyLayout />
      }
    </>
  );
};

export default Statistics;
