import React from 'react';
import { Modal } from 'react-responsive-modal';
import InputMask from 'react-input-mask';
import { MODAL_CLOSE_ICON } from 'utils/const';
import RequestApi from 'services/RequestApi';
import Errors from 'components/errors';
import { FormattedMessage } from 'react-intl';
import imageUserAdded from 'assets/img/user-added.svg';
import { REACT_APP_API_URL_USERS } from '../../config';

const AddUser = ({ onAddNewUser }) => {
  const [modalUserAdd, setOpenUserAdd] = React.useState(false);
  const [modalUserSuccess, setOpenUserSuccess] = React.useState(false);
  const [userData, setUserData] = React.useState({
    has_status_plus: false,
  });
  const [phoneError, setPhoneError] = React.useState(false);
  const [requestError, setRequestError] = React.useState(null);

  const addNewUser = async () => {
    setPhoneError(false);

    const resAddNewUser = await RequestApi.postData(`${REACT_APP_API_URL_USERS}/create`, JSON.stringify({
      name: userData.name,
      phone: userData.phone,
      has_status_plus: userData.has_status_plus,
    }));

    if (!resAddNewUser) {
      setRequestError(false);
      setOpenUserAdd(false);
      onAddNewUser();
      setOpenUserSuccess(true);
      return;
    }

    const { errors = null } = resAddNewUser;

    if (!errors) {
      return;
    }

    setRequestError(errors);
  };

  const submitUser = async (e) => {
    e.preventDefault();
    setRequestError(false);
    let { phone = '' } = userData;
    phone = phone.trim().replace(/[-+ \(\)]/g, '');
    if (phone.match(/^[0-9]{12}/g)) {
      setUserData({ ...userData, phone: userData.phone });
      await addNewUser();
      return;
    }

    setPhoneError(true);
  };

  return (
    <>
      <button className="btn btn-outline btn-add" onClick={() => setOpenUserAdd(true)}>
        <FormattedMessage id="users.addUser.addNewManager" />
      </button>
      <Modal
        open={modalUserAdd}
        onClose={() => setOpenUserAdd(false)}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="users.addUser.modal.title" />
        </div>
        <form action="" onSubmit={submitUser}>
          <div className="form-group">
            <div className="form-label">
              <FormattedMessage id="users.addUser.modal.nameLabel" />
            </div>
            <input
              className="form-control"
              onChange={(event) => setUserData({ ...userData, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <div className="form-label">
              <FormattedMessage id="users.addUser.modal.phoneNumberLabel" />
            </div>
            <InputMask
              mask="+38 (999) 999-99-99"
              maskChar=" "
              name="userPhone"
              className="form-control"
              placeholder="+38 (000) 000-00-00"
              onChange={(event) => setUserData({ ...userData, phone: event.target.value })}
            />
            { phoneError && <div className="error"><FormattedMessage id="users.addUser.modal.phoneNumber.validation.wrong" /></div> }
            { requestError && <Errors errors={requestError} /> }
          </div>
          <div className="form-group">
            <div className="flex">
              <div className="w-50">
                <div className="users-status status-plus">
                  <FormattedMessage id="users.addUser.modal.statusPlusLabel" />
                </div>
                <div className="label">
                  <FormattedMessage id="users.addUser.modal.ordersAccess" />
                </div>
              </div>
              <div className="text-right w-50">
                <label className="switch">
                  <input type="checkbox" onChange={(event) => setUserData({ ...userData, has_status_plus: event.target.checked })} />
                  <span />
                </label>
              </div>
            </div>
          </div>
          <div className="text-center">
            <span className="btn btn-simple -success" onClick={() => setOpenUserAdd(false)}>
              <FormattedMessage id="users.addUser.modal.disline" />
            </span>
            <button type="submit" className="btn">
              <FormattedMessage id="users.addUser.modal.accept" />
            </button>
          </div>
        </form>
      </Modal>
      <Modal
        open={modalUserSuccess}
        onClose={() => setOpenUserSuccess(false)}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title -sub">
          <FormattedMessage id="users.addUser.modal.success.title" />
        </div>
        <div className="text-center">
          <img src={imageUserAdded} alt="" />
        </div>
        <div className="modal-title">{userData.phone}</div>
        <div className="form-group text-center">
          <div className="label">
            <FormattedMessage id="users.addUser.modal.success.text" />
          </div>
        </div>
        <div className="text-center">
          <span className="btn" onClick={() => setOpenUserSuccess(false)}>
            <FormattedMessage id="users.addUser.modal.success.accept" />
          </span>
        </div>
      </Modal>
    </>
  );
};

export default AddUser;
