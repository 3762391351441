import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';

import messages from 'i18n/ua/messages';
import ProtectedRoute from 'components/protected-route';
import Header from 'components/header';
import Aside from 'components/aside';
import Footer from 'components/footer';
import Login from 'pages/login';
import Catalog from 'pages/catalog';
import Notice from 'pages/notifications';
import CreateNotification from 'pages/create-notifications';
import NotificationPage from 'pages/notification';
import Users from 'pages/users';
import Account from 'pages/account';
import Statistic from 'pages/statistic';
import Info from 'pages/info';
import Orders from 'pages/orders';
import Terms from 'pages/terms';
import { isMobile } from 'utils/helpers';
import { askForPermissioToReceiveNotifications, notificationListener } from 'push-notification';

function App() {
  const userData = useSelector(state => state.userStore.userData);

  useEffect(() => {
    if (!isMobile()) {
      (async function () {
        await askForPermissioToReceiveNotifications();
        notificationListener();
      })();
    }
  }, [userData]);

  return (
    <IntlProvider locale="ru" messages={messages}>
      <div className="App">
        <Router>
          <Switch>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/">
              <>
                <Header />
                <div className="container">
                  <div className="flex">
                    <Aside />
                    <div className="content">
                      <Switch>
                        <Redirect from="/" exact to="/orders/all" />
                        <Redirect from="/orders" exact to="/orders/all" />
                        <ProtectedRoute path="/orders" component={Orders} />
                        <ProtectedRoute path="/catalog" component={Catalog} />
                        <ProtectedRoute path="/users" component={Users} />
                        <ProtectedRoute path="/notifications" exact component={Notice} />
                        <ProtectedRoute path="/notifications/:id" component={NotificationPage} />
                        <ProtectedRoute path="/account" component={Account} />
                        <ProtectedRoute path="/statistic" component={Statistic} />
                        <ProtectedRoute path="/info" component={Info} />
                        <ProtectedRoute path="/terms" component={Terms} />
                        <ProtectedRoute path="/create-notification" component={CreateNotification} />
                      </Switch>
                    </div>
                  </div>
                </div>
                <Footer show={userData} />
              </>
            </Route>
          </Switch>
        </Router>
      </div>
    </IntlProvider>
  );
}

export default App;
