import React from 'react';
import { FormattedMessage } from 'react-intl';
import './styles.scss';

const Terms = () => {

  return (
    <>
      <h2>
        <FormattedMessage id="terms.title" />
      </h2>

      <p>
        <FormattedMessage id="terms.text" />
      </p>

      <ul className='terms-list'>
        <li><FormattedMessage id="terms.list.firstParagraph.title" />
          <ul>
            <li><FormattedMessage id="terms.list.firstParagraph.one" />
              <ul>
                <li><FormattedMessage id="terms.list.firstParagraph.two" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.three" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.four" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.five" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.six" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.seven" /></li>
                <li><FormattedMessage id="terms.list.firstParagraph.eight" /></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><FormattedMessage id="terms.list.secondParagraph.title"/>
          <ul>
            <li><FormattedMessage id="terms.list.secondParagraph.one" /></li>
            <li><FormattedMessage id="terms.list.secondParagraph.two" />
              <ul>
                <li><FormattedMessage id="terms.list.secondParagraph.three" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.four" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.five" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.six" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.seven" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.eight" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.nine" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.ten" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.eleven" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.twelve" /></li>
                <li><FormattedMessage id="terms.list.secondParagraph.thirteen" /></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><FormattedMessage id="terms.list.thirdParagraph.title" />
          <ul>
            <li><FormattedMessage id="terms.list.thirdParagraph.one" />
                <ul>
                  <li><FormattedMessage id="terms.list.thirdParagraph.two" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.three" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.four" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.five" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.six" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.seven" /></li>
                  <li><FormattedMessage id="terms.list.thirdParagraph.eight" /></li>
                </ul>
            </li>
            <li><FormattedMessage id="terms.list.thirdParagraph.nine" /></li>
          </ul>
        </li>
        <li><FormattedMessage id="terms.list.forthParagraph.title" />
          <ul>
            <li><FormattedMessage id="terms.list.forthParagraph.one" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.two" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.three" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.four" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.five" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.six" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.seven" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.eight" />
              <ul>
                <li><FormattedMessage id="terms.list.forthParagraph.nine" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.ten" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.eleven" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.twelve" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.thirteen" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.fourteen" /></li>
                <li><FormattedMessage id="terms.list.forthParagraph.fifteen" /></li>
              </ul>
            </li>
            <li><FormattedMessage id="terms.list.forthParagraph.sixteenth" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.seventeenth" /></li>
            <li><FormattedMessage id="terms.list.forthParagraph.eighteenth" /></li>
          </ul>
        </li>
        <li><FormattedMessage id="terms.list.fifthParagraph.title" />
          <ul>
            <li><FormattedMessage id="terms.list.fifthParagraph.one" /></li>
            <li><FormattedMessage id="terms.list.fifthParagraph.two" /></li>
            <li><FormattedMessage id="terms.list.fifthParagraph.three" />
              <ul>
                <li><FormattedMessage id="terms.list.fifthParagraph.four" /></li>
                <li><FormattedMessage id="terms.list.fifthParagraph.five" /></li>
              </ul>
            </li>
            <li><FormattedMessage id="terms.list.fifthParagraph.six" /></li>
            <li><FormattedMessage id="terms.list.fifthParagraph.seven" />
              <ul>
                <li><FormattedMessage id="terms.list.fifthParagraph.eight" /></li>
                <li><FormattedMessage id="terms.list.fifthParagraph.nine" /></li>
                <li><FormattedMessage id="terms.list.fifthParagraph.ten" /></li>
                <li><FormattedMessage id="terms.list.fifthParagraph.eleven" /></li>
              </ul>
            </li>
          </ul>
        </li>
        <li><FormattedMessage id="terms.list.sixthParagraph.title" />
          <ul>
            <li><FormattedMessage id="terms.list.sixthParagraph.one" /></li>
            <li><FormattedMessage id="terms.list.sixthParagraph.two" /></li>
            <li><FormattedMessage id="terms.list.sixthParagraph.three" /></li>
            <li><FormattedMessage id="terms.list.sixthParagraph.four" /></li>
          </ul>
        </li>
      </ul>
    </>
  );
};

export default Terms;
