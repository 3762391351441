import React, { useEffect, useState} from 'react';
import { useSelector } from 'react-redux';
import  { Redirect } from 'react-router-dom'
import RequestApi from '../../services/RequestApi';
import StatisticList from '../../components/statistic/statistics';
import ReactPaginate from 'react-paginate';
import { getFormattedDateForDatepicker } from '../../utils/helpers';
import { REACT_APP_API_URL_STATISTIC } from '../../config';
import './styles.scss';

const Statistic = () => {
  const [statistic, setStatistic] = useState(null);
  const [startDate, setStartDate] = useState(getFormattedDateForDatepicker(new Date(), true));
  const [endDate, setEndDate] = useState(getFormattedDateForDatepicker(new Date()));
  const [pageCount, setPageCount] = useState(1);
  const [forcePage, setForcePage] = useState(0);
  const [statisticTotal, setStatisticTotal] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)

  const userData = useSelector((state) => state.userStore.userData);
  if (userData && !userData.user.is_super_admin) {
    return <Redirect to='/account'  />
  }

  const fetchStatistic = async(page) => {
    setForcePage(page.selected);
    const pageToGo = page.selected + 1;

    const res = await RequestApi.getData(`${REACT_APP_API_URL_STATISTIC}?from=${startDate}&to=${endDate}&page=${pageToGo}&per_page=${process.env.REACT_APP_DATA_PER_PAGE}`,
      {}
    );
    const {
      statistics,
      total,
      errors,
      logins_for_period: loginsForPeriod,
      registration_for_period: registrationForPeriod,
      total_registered: totalRegistered
    } = res;

    if (errors) {
      return;
    }
    setPageCount(total / process.env.REACT_APP_DATA_PER_PAGE);
    setCurrentPage(pageToGo)
    setStatistic(statistics);
    setStatisticTotal({loginsForPeriod, registrationForPeriod, totalRegistered} )
  };

  useEffect(() => {
    fetchStatistic({ selected: 0 });
  }, [startDate, endDate]);

  return (
    <>
      {statistic && <StatisticList
          statistic={statistic}
          startDate={startDate}
          endDate={endDate}
          statisticTotal={statisticTotal}
          onChangeEndDate={(date) => {
            setEndDate(getFormattedDateForDatepicker(date));
          }}
          onChangeStartDate={(date) => {
            setStartDate(getFormattedDateForDatepicker(date));
          }}
          currentPage={currentPage}/>
      }

      {
        pageCount > 1 && (
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel="..."
            breakClassName="break-me"
            pageCount={pageCount}
            marginPagesDisplayed={1}
            pageRangeDisplayed={2}
            onPageChange={fetchStatistic}
            containerClassName="pagination"
            subContainerClassName="pages pagination"
            pageLinkClassName="page-link"
            activeClassName="active"
            forcePage={forcePage}
          />
        )
      }
    </>
  );
};

export default Statistic;
