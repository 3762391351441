import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import AddUser from './addUser';
import { MODAL_CLOSE_ICON } from 'utils/const';
import HeaderMobile from 'components/header/parts/headerMobile';
import { FormattedMessage, useIntl } from 'react-intl';
import RequestApi from 'services/RequestApi';
import { REACT_APP_API_URL_USERS } from '../../config';
import 'pages/users/styles.scss';
import 'react-responsive-modal/styles.css';

const Users = ({ users, onChangeUserList }) => {
  const userData = useSelector((state) => state.userStore.userData);
  const userStatus = userData ? userData.user.has_status_plus : '';
  const formattedMessage = useIntl().formatMessage;

  const [modalUserRemove, setOpenUserRemove] = useState(false);
  const [modalUserRemoveError, setOpenUserRemoveError] = useState(false);
  const [userRemoveErrorText, setUserRemoveErrorText] = useState(null);
  const [modalUserEdit, setOpenUserEdit] = useState(false);
  const [userActive, setUserActive] = useState(0);
  const [userActiveInfo, setUserActiveInfo] = useState({ name: 'Undefined', phone: 'undefined', has_status_plus: false });

  const changeUserList = () => {
    onChangeUserList();
  };

  const removeUser = async () => {
    const res = await RequestApi.postData(`${REACT_APP_API_URL_USERS}/${userActive}`, null, {}, 'delete');
    setOpenUserRemove(false);
    if (res && res.errors) {
      setUserRemoveErrorText(res.errors[0].message);
      setOpenUserRemoveError(true);
      return;
    }
    changeUserList();
  };

  useEffect(() => {
    if (!users.length) {
      return;
    }

    const userEdit = users.find(({ id }) => id === userActive);

    if (!userEdit) {
      return;
    }

    setUserActiveInfo({
      name: userEdit.name,
      phone: userEdit.phone.formatted,
      has_status_plus: userEdit.has_status_plus,
    });
  }, [userActive]);

  const editUser = async () => {
    await RequestApi.postData(`${REACT_APP_API_URL_USERS}/${userActive}`, JSON.stringify({
      name: userActiveInfo.name,
      phone: userActiveInfo.phone,
      has_status_plus: userActiveInfo.has_status_plus,
    }), {}, 'put');

    changeUserList();
    setOpenUserEdit(false);
  };

  const UsersEmptyLayout = () => (
    <div className="text-center">
      <div className="text-big -gray-dark">
        <FormattedMessage id="users.empty" />
      </div>
    </div>
  );

  const UsersLayout = ({ users }) => (
    <ul className="users-list">
      {
          users.map(({
            id, name, phone, has_status_plus,
          }) => {
            const userStatus = has_status_plus ? 'Plus' : '-';
            const userStatusClass = has_status_plus ? 'status-plus' : '';

            return (
              <li className="users-item" key={id}>
                <span className="users-name">{name}</span>
                <span className="users-phone">{phone.formatted}</span>
                <span className={`users-status ${userStatusClass}`}>{userStatus}</span>
                <span className="users-controls">
                  <button className="users-controls-btn -edit" onClick={() => { setUserActive(id); setOpenUserEdit(true); }}>
                    <span className="icon-edit" />
                  </button>
                  <button className="users-controls-btn -remove" onClick={() => { setUserActive(id); setOpenUserRemove(true); }}>
                    <span className="icon-remove" />
                  </button>
                </span>
              </li>
            );
          })
        }
    </ul>
  );

  return (
    <>
      <div className="users-top">
        <HeaderMobile title={formattedMessage({ id: 'users.headerMobile.title' })} />
        <span className="desktop text-bolder">
          <FormattedMessage id="users.title" />
        </span>
        { userStatus && <AddUser onAddNewUser={changeUserList} /> }
      </div>
      {
        users.length
          ? (
            <div>
              <div className="users-header desktop">
                <div className="users-name">
                  <span className="label">
                    <FormattedMessage id="users.nameLabel" />
                  </span>
                </div>
                <div className="users-phone">
                  <span className="label">
                    <FormattedMessage id="users.phoneLabel" />
                  </span>
                </div>
                <div className="users-status">
                  <span className="label">
                    <FormattedMessage id="users.statusLabel" />
                  </span>
                </div>
              </div>
              <UsersLayout users={users} />
              <Modal
                open={modalUserRemoveError}
                onClose={() => setOpenUserRemoveError(false)}
                center
                closeIcon={MODAL_CLOSE_ICON}
                classNames={{
                  overlay: 'modal-overlay',
                  modal: 'modal-default',
                }}
              >
                <div className="modal-title">
                  {userRemoveErrorText}
                </div>
                <div>
                  <div className="text-center">
                    <span className="btn btn-simple" onClick={() => setOpenUserRemoveError(false)}>
                      <FormattedMessage id="users.close.error.modal"/>
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                open={modalUserRemove}
                onClose={() => setOpenUserRemove(false)}
                center
                closeIcon={MODAL_CLOSE_ICON}
                classNames={{
                  overlay: 'modal-overlay',
                  modal: 'modal-default',
                }}
              >
                <div className="modal-title">
                  <FormattedMessage id="users.modal.remove.title" />
                </div>
                <div>
                  <div className="text-center">
                    <span className="btn btn-simple" onClick={() => { setUserActive(0); setOpenUserRemove(false); }}>
                      <FormattedMessage id="users.modal.remove.decline" />
                    </span>
                    <span className="btn btn-danger" onClick={() => removeUser()}>
                      <FormattedMessage id="users.modal.remove.accept" />
                    </span>
                  </div>
                </div>
              </Modal>
              <Modal
                open={modalUserEdit}
                onClose={() => setOpenUserEdit(false)}
                center
                closeIcon={MODAL_CLOSE_ICON}
                classNames={{
                  overlay: 'modal-overlay',
                  modal: 'modal-default',
                }}
              >
                <div className="modal-title">
                  <FormattedMessage id="users.modal.edit.title" />
                </div>
                <div className="form-group">
                  <div className="form-label">
                    <FormattedMessage id="users.modal.edit.nameLabel" />
                  </div>
                  <input
                    className="form-control"
                    value={userActiveInfo.name}
                    onChange={(event) => setUserActiveInfo({ ...userActiveInfo, name: event.target.value })}
                  />
                </div>
                <div className="form-group">
                  <div className="form-label">
                    <FormattedMessage id="users.modal.edit.phoneNumberLabel" />
                  </div>
                  <input className="form-control" value={userActiveInfo.phone} disabled />
                </div>
                <div className="form-group">
                  <div className="flex">
                    <div className="w-50">
                      <div className="users-status status-plus">
                        <FormattedMessage id="users.modal.edit.statusPlusLabel"/>
                      </div>
                      <div className="label">
                        <FormattedMessage id="users.modal.edit.ordersAccess" />
                      </div>
                    </div>
                    <div className="text-right w-50">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={userActiveInfo.has_status_plus}
                          onChange={(event) => setUserActiveInfo({ ...userActiveInfo, has_status_plus: event.target.checked })}
                        />
                        <span />
                      </label>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <span className="btn btn-simple -success" onClick={() => setOpenUserEdit(false)}>
                    <FormattedMessage id="users.modal.edit.decline" />
                  </span>
                  <span className="btn" onClick={() => editUser()}>
                    <FormattedMessage id="users.modal.edit.accept" />
                  </span>
                </div>
              </Modal>
            </div>
          )
          : <UsersEmptyLayout />
      }
    </>
  );
};

export default Users;
