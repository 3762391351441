import firebase from 'firebase/app';
import 'firebase/messaging';
import { FIREBASE_TOKEN_KEY } from 'utils/const';
import RequestApi from 'services/RequestApi';
import store from './store';
import LocalStorage from 'services/LocalStorage';
import { REACT_APP_API_URL_ACCOUNT, FIREBASE_CONFIGS } from './config';
import { increaseNotificationCounter } from './store/reducers/notificationCounter';

export const initializeFirebase = () => {
  firebase.initializeApp(FIREBASE_CONFIGS);
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = firebase.messaging();
    const token = await messaging.getToken();
    const savedToken = LocalStorage.get(FIREBASE_TOKEN_KEY);
    if (savedToken === token) {
      return;
    }

    LocalStorage.set(FIREBASE_TOKEN_KEY, token);

    try {
      await RequestApi.postData(`${REACT_APP_API_URL_ACCOUNT}/registration_id`, JSON.stringify({
        registration_id: token,
      }), {}, 'put');
    } catch (e) {
      console.error(e.message);
    }

  } catch (error) {
    console.error(error);
  }
};

export const notificationListener = () => {
  const messaging = firebase.messaging();
  messaging.onMessage((message) => {
    store.dispatch(increaseNotificationCounter({}));
    const { notification: { body, title } } = message;
    new Notification(title, { body });
  });
}
