export const REACT_APP_API_URL_ACCOUNT = `${process.env.REACT_APP_API_URL}/users/me`;
export const REACT_APP_API_URL_LOGOUT = `${REACT_APP_API_URL_ACCOUNT}/devices/current`;
export const REACT_APP_API_URL_VERIFY_PHONE = `${process.env.REACT_APP_API_URL}/auth/verify-phone`;
export const REACT_APP_API_URL_VERIFY_CODE = `${process.env.REACT_APP_API_URL}/auth/verify-code`;
export const REACT_APP_API_URL_ORDERS = `${process.env.REACT_APP_API_URL}/orders`;
export const REACT_APP_API_URL_USERS = `${process.env.REACT_APP_API_URL}/users`;
export const REACT_APP_API_URL_USERS_PHONES = `${process.env.REACT_APP_API_URL}/users/phones`;
export const REACT_APP_API_URL_FEEDBACK = `${process.env.REACT_APP_API_URL}/company/feedbacks`;
export const REACT_APP_API_URL_STATISTIC = `${process.env.REACT_APP_API_URL}/users/statistics`;
export const REACT_APP_API_URL_STATISTIC_ANALITICS = `${process.env.REACT_APP_API_URL}/users/csv`;
export const REACT_APP_API_URL_HELP = `${process.env.REACT_APP_API_URL}/help`;
export const REACT_APP_API_URL_NOTIFICATIONS = `${process.env.REACT_APP_API_URL}/notifications`;
export const FIREBASE_CONFIGS = {
	apiKey: "AIzaSyCMeiXzsDn3YcH7qGi4UboJN069A1I9fe4",
	authDomain: "stanz-c8a9e.firebaseapp.com",
	projectId: "stanz-c8a9e",
	storageBucket: "stanz-c8a9e.appspot.com",
	messagingSenderId: "720401274726",
	appId: "1:720401274726:web:b7f7bf791a69aceda25aa7",
	measurementId: "G-57RNGZS8DD"
};
