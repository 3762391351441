import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import Loader from 'components/loader';
import { FormattedMessage } from 'react-intl';

const HeaderDebt = () => {
  const userData = useSelector((state) => state.userStore.userData);

  if (!userData) {
    return <Loader size="small" />;
  }

  const userDebt = userData.companies.debt;
  const userOverdue = userData.companies.overdue_days;

  return (
    <div className={classNames('header-info', { '-danger': userDebt.raw > 0 })}>
      <div className="header-info-col">
        <div className="header-info-label">
          {userDebt.raw > 0 && (
            <FormattedMessage id="headerDebt.info" />
          )}
          {userDebt.raw < 0 && (
              <FormattedMessage id="orderDetails.prepayment" />
          )}
          {userDebt.raw === 0 && (
              <FormattedMessage id="orderDetails.debetZero" />
          )}
        </div>
        <div className="header-info-value">
          <span className="desktop">{userDebt.formatted.replace('-', '')}</span>
          <span className="mobile">
            {' '}
            {userDebt.raw > 0 ? ` ${userDebt.formatted} ` : (<FormattedMessage id="headerDebt.noDebt" />)}
          </span>
        </div>
      </div>
      {userOverdue > 0 && userDebt.raw > 0 && (
      <div className="header-info-col">
        <div className="header-info-label desktop">
          <FormattedMessage id="headerDebt.delay" />
        </div>
        <div className="header-info-value">
          <span className="mobile">{'/ '}</span>
          {userOverdue}
          {' '}
          <FormattedMessage id="days" />
        </div>
      </div>
      ) }
    </div>
  );
};

export default HeaderDebt;
