export default {
  'days': 'днів',

  'header.title': 'Замовлення',

  'headerLogin.manager': 'Менеджер',
  'headerLogin.link': 'Вийти з облікового запису',

  'headerDebt.info': 'Прострочена заборгованість',
  'headerDebt.noDebt': 'Немає заборгованостей',
  'headerDebt.delay': 'Прострочення',

  'contact.feedback': 'Написати нам',

  'contact.modal.feedback.title': 'Написати нам',
  'contact.modal.feedback.textarea.placeholder': 'Текст повідомлення',
  'contact.modal.feedback.message': 'Ваше повідомлення',
  'contact.modal.feedback.decline': 'Скасувати',
  'contact.modal.feedback.accept': 'Відправити',

  'contact.modal.success.title': 'Запит прийнято',
  'contact.modal.success.secondTitle': 'Дякуємо! Ваш запит прийнято!',
  'contact.modal.success.text': 'Ми вам відповімо найближчим часом',
  'contact.modal.success.close': 'Повернутися на головну',

  'contact.modal.delete.title': 'Ви впевнені, що хочете скасувати? Ваше повідомлення буде видалено',
  'contact.modal.delete.decline': 'Скасувати',
  'contact.modal.delete.delete': 'Видалити',

  'devices.unknownDevice': 'Невідомий пристрій',
  'devices.firstDeviceEnter': 'Перший вхід до облікового запису: ',
  'devices.recentActions': 'Нещодавні дії',
  'devices.leaveDevice': 'Вийти з пристрою',
  'devices.modal.title': 'Ви впевнені, що хочете видалити цей пристрій?',
  'devices.modal.decline': 'Скасувати',
  'devices.modal.accept': 'Видалити',

  'aside.message': 'Сервіс працює у тестовому режимі',
  'aside.info': 'Телефон для довідки',
  'aside.menuItem.order': 'Замовлення',
  'aside.menuItem.catalogue': 'Каталог',
  'aside.menuItem.users': 'Користувачі',
  'aside.menuItem.account': 'Обліковий запис',
  'aside.menuItem.notifications': 'Повідомлення',
  'aside.menuItem.statistic': 'Статистика',
  'aside.menuItem.info': 'Довідка',
  'aside.menuItem.create.notification': 'Push повідомлення',

  'orderDetails.title': 'Інформація про замовлення',
  'orderDetails.copyCode': 'Код скопійовано в буфер обміну',
  'orderDetails.paymentSystemImg.alt': 'Можливі платіжні системи',
  'orderDetails.orderNameLabel': 'Назва замовлення',
  'orderDetails.dateLabel': 'Запланована готовність',
  'orderDetails.shippedDateLabel': 'Відвантажений',
  'orderDetails.progress': 'Виготовлення',
  'orderDetails.decoratedLabel': 'Оформлено',
  'orderDetails.priceLabel': 'Сума замовлення',
  'orderDetails.debtLabel': 'Сума заборгованості',
  'orderDetails.prepayment': 'Передплата',
  'orderDetails.debetZero': 'Немає заборгованостей',
  'orderDetails.delayDateLabel': 'Прострочення',
  'orderDetails.label': 'Ви можете оплатити замовлення онлайн або при отриманні',
  'orderDetails.payDebt': 'Сплатити заборгованість',
  'orderDetails.deliveryInfoLabel': 'Інформація про доставку',
  'orderDetails.deliveryLabel': 'Перевізник',
  'orderDetails.declarationNumberLabel': '№ декларації',
  'orderDetails.senderLabel': 'Відправник',
  'orderDetails.managerLabel': 'Менеджер ТОВ “Штанц-технологія”',
  'orderDetails.clientManagerLabel': 'Менеджер клієнта',
  'orderDetails.modal.title': 'Оплата заказу',
  'orderDetails.modal.text': 'Сума оплати',
  'orderDetails.modal.accept': 'Сплатити',
  'orderDetails.modal.paymentTerms': 'Умови оплати',

  'orderItem.paymentStatusLabel': 'Статус оплати',
  'orderItem.deliveryTypeLabel': 'Тип доставки',

  'ordersList.number': 'Номер',
  'ordersList.orderStatus': 'Статус',
  'ordersList.paymentStatus': 'Статус оплати',
  'ordersList.deliveryType': 'Тип доставки',
  'ordersList.deliveryDate': 'Запланована дата',
  'ordersList.noActiveOrders': 'У Вас немає активних замовлень',
  'ordersList.orderName': "Ім'я",

  'search.title': 'Пошук',
  'search.orderNumberLabel': 'Номер замовлення',
  'search.button.placeholder': 'Пошук',

  'users.addUser.modal.title': 'Додавання нового менеджера',
  'users.addUser.modal.nameLabel': "Ім'я",
  'users.addUser.modal.phoneNumberLabel': 'Номер телефону',
  'users.addUser.modal.phoneNumber.validation.wrong': 'Введіть коректний номер телефону.',
  'users.addUser.modal.statusPlusLabel': 'Статус Plus',
  'users.addUser.modal.ordersAccess': 'Доступ до всіх замовлень',
  'users.addUser.modal.accept': 'Додати',
  'users.addUser.modal.disline': 'Скасувати',
  'users.addUser.addNewManager': 'Додати нового менеджера',

  'users.headerMobile.title': 'Користувачі',

  'users.addUser.modal.success.title': 'Менеджера додано до системи',
  'users.addUser.modal.success.text': 'Найближчим часом ми повідомимо йому про це по СМС',
  'users.addUser.modal.success.accept': 'Продовжити',

  'users.empty': 'Нам нема чого відобразити для вас',
  'users.title': 'Користувачі',
  'users.nameLabel': "Ім'я",
  'users.phoneLabel': 'Телефон',
  'users.statusLabel': 'Статус',
  'users.modal.remove.title': 'Ви впевнені, що хочете видалити менеджера?',
  'users.modal.remove.decline': 'Скасувати',
  'users.modal.remove.accept': 'Видалити',

  'users.close.error.modal': 'Закрити',

  'users.modal.edit.title': 'Зміна даних менеджера',
  'users.modal.edit.nameLabel': "Ім'я",
  'users.modal.edit.phoneNumberLabel': 'Номер телефону',
  'users.modal.edit.statusPlusLabel': 'Статус Plus',
  'users.modal.edit.ordersAccess': 'Доступ до всіх замовлень',
  'users.modal.edit.decline': 'Скасувати',
  'users.modal.edit.accept': 'Змінити',

  'account.tabs.devices': 'Пристрої',
  'account.tabs.contacts': 'Контакти',
  'account.tabs.tour': 'Тур по виробництву',
  'account.fullnameLabel': 'П.І.Б.',
  'account.phoneNumberLabel': 'Телефон',
  'account.companyLabel': 'Компанія',
  'account.documentsLabel': 'Документи',
  'account.passLabel': 'Перепустки',
  'account.addressLabel': 'Адреса',
  'account.address': 'вул. Греківська, 102, Харків, Харківська область, 61000',
  'account.mapLabel': 'Мапа',

  'catalog.title': 'Каталог',
  'catalog.indev': 'Сторінка в розробці',
  'catalog.backToOrders': 'Повернутись до замовлень',

  'login.changeNumber': 'Змінити номер',
  'login.smsTitle': 'Ви отримаєте SMS з кодом підтвердження',
  'login.reSendLabel': 'Відправити ще раз',
  'login.acceptCode': 'Код підтвердження',
  'login.continue': 'Продовжити',
  'login.phoneLabel': 'Номер телефону',
  'login.form.validationError': 'Введіть коректний номер телефону.',
  'login.enter': 'Увійти',
  'login.description': 'Ваш обліковий запис створюється автоматично за наявності хоча б 1 замовлення',

  'notifications.noNotifications': 'У вас поки що немає повідомлень',
  'notifications.settings': 'Налаштування повідомлень',
  'notifications.turnOn': 'Включити повідомлення',
  'notifications.modal.settings.decline': 'Скасувати',
  'notifications.modal.settings.accept': 'Продовжити',
  'notifications.headerMobile': 'Повідомлення',

  'orders.modal.paymentSuccess.title': 'Успішна оплата',
  'orders.modal.paymentSuccess.thanks': 'Дякуємо, оплата пройшла успішно',
  'orders.modal.paymentSuccess.close': 'Повернутись до замовлень',
  'orders.modal.paymentError.title': 'Помилка оплати',
  'orders.modal.paymentError.close': 'Повернутись до замовлень',
  'orders.modal.paymentError.tryAgain': 'Спробувати ще раз',
  'orders.modal.askPermissions.title': 'Дозволити відправлення повідомлень?',
  'orders.modal.askPermissions.decline': 'Ні',
  'orders.modal.askPermissions.accept': 'Так',
  'orders.tabs.one': 'Всі',
  'orders.tabs.two': 'замовлення',
  'orders.tabs.three': 'Ваші',
  'orders.tabs.four': 'Менеджерів',
  'orders.tabs.five': 'Архів',

  'terms.title': 'ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ',
  'terms.text': 'Ця угода про конфідеційність розроблена у відповідності до вимог Закону України від 01.06.2010 року № 2297-VI «Про захист персональних даних» (із змінами та доповненнями), іншого діючого Законодавства України, що регламентує правовідносини, пов\'язані зі збором, обробкою, зберіганням персональних даних, а також правом громадян на невтручання в особисте життя та правом на самовираження і описує Політику конфіденційності (далі - Політика) для Товариство з обмеженою відповідальністю «ШТАНЦ-ТЕХНОЛОГІЯ» (далі - Компанія), що зареєстрована та діє відповідно до діючого законодавства України з місцезнаходженням: 61010, м. Харків, вул. Греківська, 102, поверх 9 (код ЄДРПОУ - 30753410).',
  'terms.list.firstParagraph.title': '1. ВИЗНАЧЕННЯ ТЕРМІНІВ',
  'terms.list.firstParagraph.one': '1.1. У даній Політиці конфіденційності використовуються такі терміни:',
  'terms.list.firstParagraph.two': '1.1.1. «Адміністрація сайту Інтернет-магазину (далі - Адміністрація сайту)» - уповноважені співробітники на управління сайтом, що діють від імені інтернет-магазину  «ШТАНЦ-ТЕХНОЛОГІЯ», які організовують обробку персональних даних, а також визначає цілі обробки персональних даних, склад персональних даних, які підлягають обробці, дії або операції, що здійснюються з персональними даними.',
  'terms.list.firstParagraph.three': '1.1.2. персональні дані - відомості чи сукупність відомостей про фізичну особу (субєкта персональних даних), яка ідентифікована або може бути конкретно ідентифікована ;',
  'terms.list.firstParagraph.four': '1.1.3. обробка персональних даних - будь-яка дія або сукупність дій, таких як збирання, реєстрація, накопичення, зберігання, адаптування, зміна, поновлення, використання і поширення (розповсюдження, реалізація, передача), знеособлення, знищення персональних даних, у тому числі з використанням інформаційних (автоматизованих) систем;',
  'terms.list.firstParagraph.five': '1.1.4. «Конфіденційність персональних даних» - обов\'язкова для дотримання Адміністратором або іншою особою, що отримала доступ до персональних даних вимога не допускати їх поширення без згоди суб\'єкта персональних даних або наявності іншого підстави визначеної Законодавством України.',
  'terms.list.firstParagraph.six': '1.1.5. «Користувач сайту Інтернет-магазину  «ШТАНЦ-ТЕХНОЛОГІЯ» - особа, яка має доступ до Сайту, за допомогою мережі Інтернет і використовує Сайт інтернет-магазину.',
  'terms.list.firstParagraph.seven': '1.1.6. «Cookies» - невеличкий фрагмент даних, відправлений веб-сервером що зберігається на комп\'ютері користувача, який веб-клієнт або веб-браузер кожен раз пересилає веб-серверу в HTTP-запиті при спробі відкрити сторінку відповідного сайту.',
  'terms.list.firstParagraph.eight': '1.1.7. «IP-адреса» — ідентифікатор (унікальний числовий номер) мережевого рівня, який використовується для адресації комп\'ютерів чи пристроїв у мережах, які побудовані з використанням протоколу TCP/IP.',

  'terms.list.secondParagraph.title': '2. ПРЕДМЕТ ПОЛІТИКИ КОНФІДЕНЦІЙНОСТІ',
  'terms.list.secondParagraph.one': '2.1. Користуючись послугами Компанії через додаток та/або інший сервіс прикладного програмного інтерфейсу, Користувач надає добровільну однозначну згоду на обробку Компанією його персональних даних, включаючи збір, систематизацію, накопичення, зберігання, уточнення (оновлення, виправлення), використання, поширення, знеособлення, блокування, знищення персональних даних, зазначених в анкеті, з метою заходів, спрямованих на формування бази даних клієнтів, складання статистичної звітності, проведення маркетингових досліджень, а також здійснення взаємодії з користувачем, за допомогою, зокрема, але не виключно, поштової розсилки, мережі Інтернет, мереж електрозв\'язку, в тому числі SMS-повідомлень і електронних листів та таке інше, до моменту відкликання такої згоди, а також підтверджує що є повнолітньою особою.',
  'terms.list.secondParagraph.two': '2.2. Обсяг персональних даних Користувача включає та/або може включати таку інформацію:',
  'terms.list.secondParagraph.three': '2.2.1. прізвище, власне ім’я, по батькові;',
  'terms.list.secondParagraph.four': '2.1.2. паспортні данні;',
  'terms.list.secondParagraph.five': '2.2.3. стать;',
  'terms.list.secondParagraph.six': '2.2.4. дата та місце народження;',
  'terms.list.secondParagraph.seven': '2.2.5. реєстраційний номер облікової картки платників податків з Державного реєстру фізичних осіб-платників податків (ідентифікаційний номер);',
  'terms.list.secondParagraph.eight': '2.2.6. місце проживання та/або реєстрації;',
  'terms.list.secondParagraph.nine': '2.2.7. номери контактних телефонів;',
  'terms.list.secondParagraph.ten': '2.2.8. адреси електронної пошти;',
  'terms.list.secondParagraph.eleven': '2.2.9. адреси доставки відправлень;',
  'terms.list.secondParagraph.twelve': '2.2.10. IP-адреса;',
  'terms.list.secondParagraph.thirteen': '2.2.11. час доступу в додаток та/або до сайту інтернет-магазину;',

  'terms.list.thirdParagraph.title': '3. МЕТА ЗБИРАННЯ ТА ОБРОБКИ ПЕРСОНАЛЬНИХ ДАНИХ',
  'terms.list.thirdParagraph.one': '3.1. Для надання своїх послуг Компанія може використовувати інформацію, яку Компанія збирає і розміщує для наступних цілей:',
  'terms.list.thirdParagraph.two': '3.1.1.\tідентифікація Користувача, зареєстрованого на сайті Інтернет-магазину та /або в додатку, для оформлення замовлення та/або укладення Договору купівлі-продажу товару дистанційним способом, забезпечення обслуговування клієнтів, в тому числі для створення і управління обліковими записами Користувача, вирішення технічних труднощів і доступу до різних функцій;',
  'terms.list.thirdParagraph.three': '3.1.2.\tадаптація пропозицій і досвіду, в тому числі реклами на своїх сервісах;',
  'terms.list.thirdParagraph.four': '3.1.3.\tконтроль загальної та індивідуальної активності Користувачів додатку;',
  'terms.list.thirdParagraph.five': '3.1.4.\tзв\'язок з Користувачами, в тому числі направлення повідомлень, запитів та інформації, що стосуються використання Сервісів, надання послуг, а також обробки запитів і заявок від Користувача, пропонування нових продуктів та послуг, обслуговування або дозволених маркетингових комунікацій через будь-які доступні канали зв\'язку тощо;',
  'terms.list.thirdParagraph.six': '3.1.5.\tпроведення науково-дослідницької та аналітичної активності на основі знеособлених даних з метою поліпшення та індивідуалізації сервісів Компанії та\\або якості надання супутніх послуг;',
  'terms.list.thirdParagraph.seven': '3.1.6.\tоцінка деяких факторів особистої інформації, зокрема, для аналізу та прогнозування особистих вподобань, інтересів, поведінки та місцезнаходження.',
  'terms.list.thirdParagraph.eight': '3.1.7. проведення статистичних та інших досліджень на основі знеособлених даних.',
  'terms.list.thirdParagraph.nine': '3.2. Компанія може зберігати інформацію, яку вона збирає і отримує при наданні послуг стільки, скільки буде потрібно для виконання вищевказаних господарських цілей.',

  'terms.list.forthParagraph.title': '4. КАТЕГОРІЇ ДАНИХ ЩО ЗБИРАЮТЬСЯ',
  'terms.list.forthParagraph.one': '4.1. При створенні Користувачем облікового запису в додатку та/або на сайті, Компанія може вимагати інформацію, перелічену в п. 2.2 цієї Політики.',
  'terms.list.forthParagraph.two': '4.2. Категорії Персональних даних, збір яких здійснює сайт та/або додаток (самостійно або за посередництвом третіх осіб), включають: Файли «cookie» і Дані про використання мережевих ресурсів.',
  'terms.list.forthParagraph.three': '4.3. Персональні дані можуть надаватися Користувачем самостійно або їх збір може автоматично здійснювати сайт при його використанні.',
  'terms.list.forthParagraph.four': '4.4. Якщо не вказано інше, сайт використовує файли «cookie» (або інші засоби відстеження) для ідентифікації користувачів і запам\'ятовування встановлених ними глобальних параметрів налаштування виключно в цілях надання послуги, що потребує Користувач. З метою забезпечення роботи сайту та його технічного обслуговування, сайт і будь-які сторонні сервіси можуть вести файли, в яких реєструється взаємодія сайту з Користувачами (системні журнали), або використовувати з цією метою інші Персональні дані (зокрема, IP-адреса).',
  'terms.list.forthParagraph.five': '4.5. В процесі роботи додатку Компанія може збирати особисту інформацію про участь Користувачів у маркетингових акціях, організованих Компанією, а також обробляти інформацію, пов\'язану з ефективністю роботи додатку.',
  'terms.list.forthParagraph.six': '4.6. При зверненні Користувачів щодо підтримки клієнтів Компанія, за необхідності, може в процесі роботи свого додатку збирати особисту інформацію, необхідну для виконання запиту Користувача і отримання зворотного зв\'язку. Компанія може також зв\'язатися з Користувачем, використовуючи існуючу контактну інформацію облікового запису, надану для цієї мети.',
  'terms.list.forthParagraph.seven': '4.7. Компанія може збирати та зберігати інформацію, що отримана в результаті опитувань, що можуть проводитись Компанією, або залученими Компанією підрядниками - третіми особами.',
  'terms.list.forthParagraph.eight': '4.8. \tКористувач зобов\'язується:',
  'terms.list.forthParagraph.nine': '4.8.1. забезпечити достовірність наданої їм інформації;',
  'terms.list.forthParagraph.ten': '4.8.2. забезпечувати збереження особистих даних від доступу третіх осіб а також не передавати в користування свій обліковий запис і / або логін і пароль свого облікового запису третім особам;',
  'terms.list.forthParagraph.eleven': '4.8.3. не здійснювати дії, спрямовані на введення інших користувачів в оману;',
  'terms.list.forthParagraph.twelve': '4.8.4. оновлювати Персональні дані, надані при реєстрації, в разі їх зміни;',
  'terms.list.forthParagraph.thirteen': '4.8.5. не розміщувати та не поширювати матеріали рекламного, еротичного, порнографічного або образливого характеру, інформацію, яка спрямована на пропаганду війни, розпалювання національної, расової чи релігійної ненависті і ворожнечі, а також іншу інформацію, розміщення якої заборонено чи суперечить нормам чинного законодавства  та за розповсюдження якої передбачена кримінальна або адміністративна відповідальність;',
  'terms.list.forthParagraph.fourteen': '4.8.6. не порушувати працездатність сайту та не використовувати скрипти (програми) для автоматизованого збору інформації та / або взаємодії з Сайтом і його Сервісами;',
  'terms.list.forthParagraph.fifteen': '4.8.7. не створювати кілька облікових записів на Сайті, якщо фактично вони належать одній і тій же особі а також не реєструвати обліковий запис від імені або замість іншої особи;',
  'terms.list.forthParagraph.sixteenth': '4.9. Користувач несе відповідальність за Персональні дані третіх осіб, які вони отримують, публікують або надають іншим особам, використовуючи сайт, і підтверджують, що у них є згода третьої особи на надання Даних Власнику.',
  'terms.list.forthParagraph.seventeenth': '4.10. Користувач має право в будь-який час дізнатися, чи були збережені їх Персональні дані, і можуть звертатися до Адміністрації сайту, щоб дізнатися їх зміст і походження, перевірити їх достовірність, або попросити їх доповнити, анулювати, оновити або виправити або перетворити в формат, що забезпечує анонімність, або блокувати будь-які дані, що зберігаються в порушення закону, а також виступити проти їх обробки за всіма без винятку законних підставах. Відповідні запити слід направляти адміністарції сайту за адресою, вказаною в контактних відомостях на сайті.',
  'terms.list.forthParagraph.eighteenth': '4.11. Користувач погоджується з тим, що Адміністрація сайту має право передавати персональні дані третім особам, зокрема, кур\'єрським службам, організаціями поштового зв\'язку, операторам електрозв\'язку, виключно з метою виконання замовлення Користувача, оформленого на Сайті Інтернет-магазину, включаючи доставку Товару.',

  'terms.list.fifthParagraph.title': '5. БЕЗПЕКА ПЕРСОНАЛЬНИХ ДАНИХ',
  'terms.list.fifthParagraph.one': '5.1. Товариство з обмеженою відповідальністю «ШТАНЦ-ТЕХНОЛОГІЯ» вживає необхідних організаційних і технічних заходів для захисту персональної інформації Користувача від неправомірного або випадкового доступу, знищення, перекручення, блокування, копіювання, поширення, а також від інших неправомірних дій третіх осіб. Афілійовані з Компанією особи, надійні партнери і незалежні постачальники послуг зобов\'язуються використовувати отриману від Компанії інформацію відповідно до вимог Компанії до безпеки і цієї Політикою.',
  'terms.list.fifthParagraph.two': '5.2. Ця Політика конфіденційності поширюється тільки на сайт Інтернет-магазину та/або додаток ТОВ «ШТАНЦ-ТЕХНОЛОГІЯ». Якщо по посиланнях, розміщених на сайті останнього, Користувач зайде на ресурси третіх осіб, Інтернет-магазин за його дії відповідальності не несе.',
  'terms.list.fifthParagraph.three': '5.3. Компанія залишає за собою право надавати на запити компетентних державних органів, оформлених відповідно до вимог чинного законодавства інформацію в наступних цілях:',
  'terms.list.fifthParagraph.four': '5.3.1. боротьба з шахрайством та іншими злочинами;',
  'terms.list.fifthParagraph.five': '5.3.2. розслідування порушень чинного законодавства України;',
  'terms.list.fifthParagraph.six': '5.4. Користувач поінформований про те, що Персональні дані Користувача можуть бути використані Компанією в юридичних цілях в суді або на етапах, що ведуть до можливого звернення до суду в зв\'язку з тим, що сайт або послуги використовувалися неналежним чином.',
  'terms.list.fifthParagraph.seven': '5.5. У разі втрати або розголошення Персональної інформації Адміністрація сайту не несе відповідальності, якщо дана конфіденційна інформація:',
  'terms.list.fifthParagraph.eight': '5.5.1. Стала публічним надбанням до її втрати або розголошення.',
  'terms.list.fifthParagraph.nine': '5.5.2. Була отримана від третьої сторони до моменту її отримання Адміністрацією сайту.',
  'terms.list.fifthParagraph.ten': '5.5.3. Була розголошена за згодою Користувача.',
  'terms.list.fifthParagraph.eleven': '5.5.4. Була розголошена у випадках предбачених п.4.10, 5.3, 5.4 цієї Угоди.',

  'terms.list.sixthParagraph.title': '6. ДОДАТКОВІ УМОВИ',
  'terms.list.sixthParagraph.one': '6.1. Компанія може оновлювати цю Політику без узгодження з Користувачем, нова редакція Політики конфіденційності вступає в силу з моменту її розміщення в мережі Інтернет, якщо інше не передбачено новою редакцією Політики.',
  'terms.list.sixthParagraph.two': '6.2. У разі незгоди з будь-якими змінами, внесеними до Правил, Користувач повинен припинити користуватися сайтом, а також вимагати, щоб Адміністрація сайту даних видалила його Персональні дані.',
  'terms.list.sixthParagraph.three': '6.3. До звернення в суд з позовом у спорах, що виникають із відносин між Користувачем і Адміністрацією сайту, обов\'язковим є пред\'явлення претензії (письмового пропозиції про добровільне врегулювання спору).',
  'terms.list.sixthParagraph.four': '.4. Отримувач претензії протягом 30 календарних днів з дня отримання претензії, письмово повідомляє заявника претензії про результати розгляду претензії. При недосягненні згоди суперечка буде передана на розгляд до суду відповідно до чинного законодавства України.',

  'statistic.emptyLabel': 'За цей проміжок часу статистика відсутня',
  'statistic.companyNameLabel': 'Назва компанії',
  'statistic.fullNameLabel': 'П.І.Б.',
  'statistic.lastEnterLabel': 'Останній вхід',
  'statistic.loginsForPeriod': 'Усього входів за період',
  'statistic.registrationForPeriod': 'Усього зареєстрованих за період',
  'statistic.totalRegistered': 'Усього зареєстрованих',
  'statistic.btnAll': 'Експорт в Exсel (аналітика)',
  'statistic.bntStat': 'Експорт в Exсel (база користувачів)',
  'statistic.countNumberOfEntersLabel': 'Кількість входів за період',
  'statistic.countNumberOfEntersLabelShort': 'Кількість входів',
  'statistic.title': 'Статистика',
  'statistic.showPeriodLabel': 'Період показу',
  'statistic.fromDate': 'Від',
  'statistic.toDate': 'До',
  'statistics.allUsers': 'Усі користувачі',
  'statistics.onlyUniqueUsers': 'Унікальні користувачі',

  'info.title': 'Довідка',
  'info.noLinks': 'Немає доступних посилань',

  'footer.rights': '© 2020. Всі права захищені.',
  'footer.terms': 'Умови використання',
  'footer.developedIn': 'Розроблено в',

  'create.notification.title': 'Написати повідомлення',
  'create.notification.sendToAll': 'Надіслати всім',
  'create.notification.send': 'Відправити',
  'create.notification.phoneNums': 'Номери телефонів',
  'create.notification.messTitle': 'Заголовок',
  'create.notification.messPlaceholder': 'Заголовок повідомлення',
  'create.notification.chars': 'символів',
  'create.notification.messSendSuccess': 'Повідомлення відправлено',
  'create.notification.messSendError': 'Помилка під час відправлення',
}
