export const TOGGLE_MOBILE_MENU = 'TOGGLE_MOBILE_MENU';
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';

export const toggleMobileMenu = () => ({
  type: TOGGLE_MOBILE_MENU,
});

export const toggleSearch = () => ({
  type: TOGGLE_SEARCH,
});

const initialState = {
  menuIsToggled: false,
  searchActive: false,
};

const layoutReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_MOBILE_MENU:
      return { ...state, menuIsToggled: !state.menuIsToggled };
    case TOGGLE_SEARCH:
      return { ...state, searchActive: !state.searchActive };
    default: return state;
  }
};

export default layoutReducer;
