import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import { MODAL_CLOSE_ICON } from 'utils/const';
import { getDateSeparatly } from 'utils/helpers';
import HeaderMobile from 'components/header/parts/headerMobile';
import Loader from 'components/loader';
import RequestApi from 'services/RequestApi';
import Errors from 'components/errors';
import { FormattedMessage, useIntl } from 'react-intl';
import { REACT_APP_API_URL_ACCOUNT } from '../../config';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { setNotificationCounter } from '../../store/reducers/notificationCounter';
import './styles.scss';
import {Link} from "react-router-dom";

const Notice = () => {
  const [error, setError] = useState(null);
  const [enableNotifications, setEnableNotifications] = useState(true);
  const [formData, setFormData] = useState({});
  const [settings, setSettings] = useState([]);
  const [modalSettings, setOpenModalSettings] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [forcePage, setForcePage] = useState(0);
  const formattedMessage = useIntl().formatMessage;
  const [noticeList, setNoticeList] = useState(false);
  const [loading, setLoading] = useState(false);
  const notificationCounter = useSelector(
    (store) => store.notificationCounterStore.notificationCounter,
  );

  const dispatch = useDispatch();

  const fetchNotice = async (page) => {
    setForcePage(page.selected);
    const pageToGo = page.selected + 1;
    setLoading(true);

    const notificationRes = await RequestApi.getData(`${REACT_APP_API_URL_ACCOUNT}/notifications?page=${pageToGo}&per_page=${process.env.REACT_APP_DATA_PER_PAGE}`);

    const { errors = null, total } = notificationRes;

    if (errors) {
      console.error(errors.message);
      return;
    }

    setPageCount(total / process.env.REACT_APP_DATA_PER_PAGE);
    setNoticeList(notificationRes);
    setLoading(false);
  };

  useEffect(() => {
    fetchNotice({ selected: 0 });
  }, []);

  const NoticeEmptyLayout = () => (
    <div className="text-center">
      <div className="text-big -gray-dark">
        <FormattedMessage id="notifications.noNotifications" />
      </div>
    </div>
  );

  const NoticeLayout = ({ notice }) => (
    <ul className="notice-list">
      {
          notice.notifications.map(({ id, title, created_at }, index) => {
            const notificationDate = getDateSeparatly(created_at);
            const {
              hour, minutes, day, month, year,
            } = notificationDate;

            return (
              <Link to={`/notifications/${id}`} className={`notice-item ${notificationCounter - index > 0 ? 'unread' : ''}`}
                  key={id}
                  onClick={() => {}}
              >
                <span>{title}</span>
                <span className="notice-date">
                  {hour}
                  :
                  {minutes}
                  {' '}
                  {day}
                  {' '}
                  {month}
                  {' '}
                  {year}
                </span>
              </Link>
            );
          })
        }
    </ul>
  );

  const getNoticeSettings = async () => {
    const resNotice = await RequestApi.getData(`${REACT_APP_API_URL_ACCOUNT}/settings`);
    const { errors = null, settings, notifiable } = resNotice;

    if (errors) {
      console.error(errors.message);
      return;
    }

    const formDataValues = {};

    settings.forEach((settingObj) => {
      const { notification: { type }, enabled } = settingObj;
      formDataValues[type] = {
        type,
        enabled,
      };
    });

    setFormData(formDataValues);
    setOpenModalSettings(true);
    setEnableNotifications(notifiable);
    setSettings(settings);
  };

  const generateSettings = () => settings.map((settingObj) => {
    const { notification: { type, name } } = settingObj;
    const checkBoxState = formData[type];

    return (
      <li className="list-links-item flex-between">
        <span>{name}</span>
        <label className="switch">
          <input
            onChange={() => setFormData({
              ...formData,
              ...{
                [type]: {
                  ...checkBoxState,
                  enabled: !checkBoxState.enabled,
                },
              },
            })}
            type="checkbox"
            checked={checkBoxState.enabled}
          />
          <span />
        </label>
      </li>
    );
  });

  const submitNoticeSettings = async (e) => {
    e.preventDefault();
    setError(false);

    const settingsBody = Object.keys(formData).map((key) => formData[key]);

    const saveNoticeRes = await RequestApi.postData(`${REACT_APP_API_URL_ACCOUNT}/notifiable`, JSON.stringify({
      notifiable: enableNotifications,
    }), {}, 'put');

    const saveSettingsRes = await RequestApi.postData(`${REACT_APP_API_URL_ACCOUNT}/settings`, JSON.stringify({
      settings: settingsBody,
    }), {}, 'put');

    if (!saveNoticeRes && !saveSettingsRes) {
      setOpenModalSettings(false);
      return;
    }

    const { errors = null } = saveNoticeRes;
    const { errors: errorsSettings = null } = saveSettingsRes;

    if (!errors || !errorsSettings) {
      return;
    }

    setError(errors || errorsSettings);
  };

  useEffect(() => {
    return () => {
        dispatch(setNotificationCounter(0));
    }
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <HeaderMobile title={formattedMessage({ id: 'notifications.headerMobile' })} />
      <div className="users-top page-title">
        <span className="desktop text-bolder">
          <FormattedMessage id="aside.menuItem.notifications" />
        </span>
        <span onClick={() => getNoticeSettings()}>
          <a className="desktop link-phone">
            <FormattedMessage id="notifications.settings" />
          </a>
          <span className="mobile notice-cog icon-cog" />
        </span>
      </div>

      { noticeList && noticeList.notifications.length > 0
        ? (
          <div>
            <NoticeLayout notice={noticeList} />
            {
              pageCount > 1 && (
                  <ReactPaginate
                      previousLabel="<"
                      nextLabel=">"
                      breakLabel="..."
                      breakClassName="break-me"
                      pageCount={pageCount}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={fetchNotice}
                      containerClassName="pagination"
                      subContainerClassName="pages pagination"
                      pageLinkClassName="page-link"
                      activeClassName="active"
                      forcePage={forcePage}
                  />
              )
            }
          </div>
        )
        : <NoticeEmptyLayout />}

      <Modal
        open={modalSettings}
        onClose={() => setOpenModalSettings(false)}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <form action="POST" onSubmit={submitNoticeSettings}>
          <div className="modal-title">
            <FormattedMessage id="notifications.settings" />
          </div>
          <div className="modal-title -sub flex-between">
            <FormattedMessage id="notifications.turnOn" />
            <label className="switch">
              <input
                onChange={() => {
                  const keys = Object.keys(formData);
                  const updatedFormData = {...formData};

                  keys.forEach((key) => updatedFormData[key].enabled = !enableNotifications);

                  setFormData(updatedFormData);
                  setEnableNotifications(!enableNotifications);
                }}
                type="checkbox"
                checked={enableNotifications}
              />
              <span />
            </label>
          </div>
          <ul className="list-links">
            { generateSettings() }
            { error && <Errors errors={error} /> }
          </ul>
          <div className="text-center">
            <span className="btn btn-simple -success" onClick={() => setOpenModalSettings(false)}>
              <FormattedMessage id="notifications.modal.settings.decline" />
            </span>
            <button className="btn" type="submit">
              <FormattedMessage id="notifications.modal.settings.accept" />
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default Notice;
