import React from 'react';
import { Route, Redirect, Prompt } from 'react-router-dom';
import { TOKEN_KEY } from 'utils/const';
import LocalStorage from 'services/LocalStorage';
import { useDispatch } from 'react-redux';
import { setOrderSearch } from '../../store/reducers/orders';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const token = LocalStorage.get(TOKEN_KEY);
  const dispatch = useDispatch();
  return (
    <Route
      {...rest}
      render={
      (props) => {
        if (token) {
          return (<><Component {...rest} {...props} /> <Prompt message={() => dispatch(setOrderSearch(''))} /></>);
        }
        return <Redirect to="/login" />;
      }
    }
    />
  );
};

export default ProtectedRoute;
