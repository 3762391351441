import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  withRouter,
} from 'react-router-dom';
import qs from 'qs';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import { MODAL_CLOSE_ICON, ORDER_STATUSES } from 'utils/const';
import RequestApi from 'services/RequestApi';
import Tabs from 'components/orders/tabs';
import Search from 'components/search';
import OrdersData from 'components/orders/ordersData';
import { FormattedMessage } from 'react-intl';
import HeaderDebt from 'components/header/parts/headerDebt';
import imagePaymentSuccess from 'assets/img/payment-success.svg';
import imagePaymentError from 'assets/img/payment-error.svg';
import { REACT_APP_API_URL_ORDERS } from '../../config';
import { isMobile } from 'utils/helpers';
import { setIsShowNotificationPopup } from '../../store/reducers/notificationCounter';
import './styles.scss';

const Orders = (props) => {
  const orderSearch = useSelector((state) => state.ordersStore.search);
  const firstOpenAskPermission = useSelector((state) => state.notificationCounterStore.show);
  const [isFirstTimeNotificationOptionWindow, setIsFirstTimeNotificationOptionWindow] = useState(true);
  const [openedPopup, setOpenedPopup] = useState('');
  const [errorInvoice, setErrorInvoice] = useState(null);
  const [orderInvoice, setOrderInvoice] = useState({});
  const match = useRouteMatch();
  const dispatch = useDispatch();

  const closePopup = () => {
    setOpenedPopup('');
    props.history.push(props.location.pathname);
  }

  useEffect(() => {
    processStatusPayment();
  }, []);

  const processStatusPayment = async () => {
    const query = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const { status = '', order_id = '' } = query;

    if (!status && !order_id) {
      return;
    }

    if (!ORDER_STATUSES.includes(status)) {
      return;
    }

    if (status === 'error') {
      const res = await RequestApi.getData(`${REACT_APP_API_URL_ORDERS}${order_id}/transactions/last-failure`);
      const { transaction = null } = res;

      if (transaction) {
        setErrorInvoice(transaction.message);
      }

      const invoiceRes = await RequestApi.getData(`${REACT_APP_API_URL_ORDERS}/${order_id}/invoice`);
      const { invoice } = invoiceRes;

      if (!invoice) {
        console.error('Can not get invoice data');
        return;
      }

      setOrderInvoice({
        bill_amount: invoice.bill_amount,
        checkout_url: invoice.checkout_url,
        description: invoice.description,
        encoding: invoice.encoding,
        expire_time: invoice.expire_time,
        failure_url: invoice.failure_url,
        lang: invoice.lang,
        payee_id: invoice.payee_id,
        shop_order_number: invoice.shop_order_number,
        success_url: invoice.success_url,
      });
    }

    setOpenedPopup(status);
  }

  if (!isMobile()) {
    if (Notification.permission === 'default' && firstOpenAskPermission && isFirstTimeNotificationOptionWindow) {
      setIsFirstTimeNotificationOptionWindow(false);
      setOpenedPopup('default');
    }
  }

  return (
    <>
      <div className="mobile">
        <HeaderDebt />
      </div>
      <div className="flex orders-tabs">
        <Tabs />
        <Search />
      </div>
      <Modal
        open={openedPopup === 'default'}
        onClose={closePopup}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="orders.modal.askPermissions.title" />
        </div>
        <div className="flex btn-wrap-modal" style={{ justifyContent: 'center'}}>
          <button className="btn btn-outline" onClick={() => {
            dispatch(setIsShowNotificationPopup(false));
            closePopup();
          }}>
            <FormattedMessage id="orders.modal.askPermissions.decline" />
          </button>
          <button className="btn" onClick={async () => {
            dispatch(setIsShowNotificationPopup(false));
            await Notification.requestPermission();
            await closePopup();
          }}>
            <FormattedMessage id="orders.modal.askPermissions.accept" />
          </button>
        </div>
      </Modal>
      <Modal
        open={openedPopup === 'success'}
        onClose={closePopup}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="orders.modal.paymentSuccess.title" />
        </div>
        <div className="text-center">
          <img src={imagePaymentSuccess} alt="" />
        </div>
        <div className="modal-title -sub">
          <FormattedMessage id="orders.modal.paymentSuccess.thanks" />
        </div>
        <div className="text-center">
          <span className="btn" onClick={closePopup}>
            <FormattedMessage id="orders.modal.paymentSuccess.close" />
          </span>
        </div>
      </Modal>
      <Modal
        open={openedPopup === 'error'}
        onClose={closePopup}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default modal-wide',
        }}
      >
        <div className="modal-title">
          <FormattedMessage id="orders.modal.paymentError.title" />
        </div>
        <div className="text-center">
          <img src={imagePaymentError} alt="" />
        </div>
        { errorInvoice && <div className="error error-info text-center">{errorInvoice}</div> }
        <div className="flex btn-wrap-modal">
            <button className="btn btn-outline btn-margins" onClick={closePopup}>
              <FormattedMessage id="orders.modal.paymentError.close" />
            </button>
            <form action={orderInvoice.checkout_url} method="post">
              <input type="hidden" name="payee_id" value={orderInvoice.payee_id} />
              <input type="hidden" name="shop_order_number" value={orderInvoice.shop_order_number} />
              <input type="hidden" name="bill_amount" value={orderInvoice.bill_amount} />
              <input type="hidden" name="description" value={orderInvoice.description} />
              <input type="hidden" name="success_url" value={orderInvoice.success_url} />
              <input type="hidden" name="failure_url" value={orderInvoice.failure_url} />
              <input type="hidden" name="lang" value={orderInvoice.lang} />
              <input type="hidden" name="encoding" value={orderInvoice.encoding} />
              <input type="hidden" name="exp_time" value={orderInvoice.expire_time} />
              <button className="btn" type="submit">
                <FormattedMessage id="orders.modal.paymentError.tryAgain" />
              </button>
            </form>
        </div>
      </Modal>
      {
            orderSearch
              ? <OrdersData searchTerm={orderSearch} url={`${REACT_APP_API_URL_ORDERS}/search`} />
              : (
                <Switch>
                  <Route path={`${match.path}/all`} exact>
                    <OrdersData url={REACT_APP_API_URL_ORDERS} />
                  </Route>
                  <Route path={`${match.path}/my`} exact>
                    <OrdersData url={`${REACT_APP_API_URL_ORDERS}/my`} />
                  </Route>
                  <Route path={`${match.path}/them`} exact>
                    <OrdersData url={`${REACT_APP_API_URL_ORDERS}/managers`} />
                  </Route>
                  <Route path={`${match.path}/archive`} exact>
                    <OrdersData url={`${REACT_APP_API_URL_ORDERS}/archive`} />
                  </Route>
                </Switch>
              )
          }
    </>
  );
};

export default withRouter(Orders);
