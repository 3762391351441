import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toggleMobileMenu } from 'store/reducers/layout';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import HeaderLogin from '../header/parts/headerLogin';
import {FormattedMessage, useIntl} from 'react-intl';
import './styles.scss';

const Aside = () => {
  const dispatch = useDispatch();
  const isOpenedMenu = useSelector((state) => state.layoutStore.menuIsToggled);
  const userData = useSelector((state) => state.userStore.userData);
  const notificationCounter = useSelector((state) => state.notificationCounterStore.notificationCounter);

  const menuItems = [
    {
      id: 1, title: (<FormattedMessage id="aside.menuItem.order" />), link: 'orders', icon: 'order',
    },
    {
      id: 2, title: (<FormattedMessage id="aside.menuItem.catalogue" />), link: 'catalog', icon: 'layers',
    },
    {
      id: 3, title: (<FormattedMessage id="aside.menuItem.users" />), link: 'users', icon: 'users',
    },
    {
      id: 4, title: (<FormattedMessage id="aside.menuItem.account" />), link: 'account', icon: 'user',
    },
    {
      id: 5, title: (<FormattedMessage id="aside.menuItem.notifications" />), link: 'notifications', icon: 'bell',
    },
    {
      id: 6, title: (<FormattedMessage id="aside.menuItem.info" />), link: 'info', icon: 'info',
    },
  ];

  if (userData && userData.user.is_super_admin) {
    menuItems.push({
      id: 7, title: (<FormattedMessage id="aside.menuItem.create.notification" />), link: 'create-notification', icon: 'create-notification',
    },{
      id: 8, title: (<FormattedMessage id="aside.menuItem.statistic" />), link: 'statistic', icon: 'stats-dots',
    });
  }

  const toggleMenu = () => {
    dispatch(toggleMobileMenu());
  };

  const MenuItemsLayout = () => (
    menuItems.map(({
      id, title, link, icon,
    }) => {
      const showBadge = link === 'notifications' && notificationCounter;
      return (
      <li key={id} className="menu-item">
        <NavLink to={`/${link}`} activeClassName="active" className="menu-link" onClick={toggleMenu}>
          <span style={{
            position: 'relative',
          }}>
            {showBadge ? (
              <span style={{ width: 11 * notificationCounter.toString().length }} className="badge">{notificationCounter}</span>
            ) : null}
            <span className={`menu-icon icon-${icon}`} />
          </span>
          <span style={{ marginLeft: showBadge ? 7 : 0}}>{title}</span>
        </NavLink>
      </li>
    )})
  );

  return (
    <div className={classNames('aside', 'aside-block', { 'menu-open': isOpenedMenu })}>
      <div className="menu">
        <div className="menu-content">
          <ul className="menu-list">
            <MenuItemsLayout />
          </ul>
          <div className="mobile">
            <HeaderLogin />
          </div>
        </div>
      </div>
      <div className="aside-info">
        <div className="aside-info-label">
          <FormattedMessage id="aside.info"/>
        </div>
        <a href="tel:+380577334190" className="link-phone">+38 (057) 733-41-90</a>
      </div>
      <div className="aside-message">
        <FormattedMessage id="aside.message"/>
      </div>
    </div>
  );
};

export default Aside;
