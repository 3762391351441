import React, { useEffect, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import RequestApi from '../../services/RequestApi';
import { REACT_APP_API_URL_ACCOUNT } from '../../config';
import Loader from '../../components/loader';
import { findAndReplaceLink } from '../../utils/helpers';
import './styles.scss';

const NotificationPage = () => {
  const {id} = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    RequestApi.getData(`${REACT_APP_API_URL_ACCOUNT}/notifications/${id}`)
      .then((res) => {
        if (res && res.notification) {
          setData(res.notification);
        }
        setLoading(false);
      });
  }, [id]);

  if (!loading && !data) {
    return <Redirect to="/" />
  }

  return data ? (
    <div className="mess-container">
      <div className="mess-title">
        {data.title || ''}
      </div>
      <p className="mess-description" dangerouslySetInnerHTML={{ __html: findAndReplaceLink(data.body) }} />
    </div>
  ) : (
    <div className="text-center">
      <Loader />
    </div>
  );
}

export default NotificationPage;
