class LocalStorage {
  static set(key, data) {
    let convertedData = '';
    try {
      convertedData = typeof data === 'object' ? JSON.stringify(data) : data;
    } catch (error) {
      console.error('Error to set localStorage data', error.message);
    }

    localStorage.setItem(key, convertedData);
  }

  static get(key) {
    return localStorage.getItem(key);
  }

  static remove(key) {
    localStorage.removeItem(key);
  }
}

export default LocalStorage;
