import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal } from 'react-responsive-modal';
import classNames from 'classnames';
import HeaderMobile from '../../components/header/parts/headerMobile';
import RequestApi from '../../services/RequestApi';
import { MODAL_CLOSE_ICON } from '../../utils/const';
import PhoneItem from './phone-item';
import PhoneTag from './phone-tag';
import Loader from '../../components/loader';
import {
  REACT_APP_API_URL_NOTIFICATIONS,
  REACT_APP_API_URL_USERS_PHONES
} from '../../config';
import './styles.scss';

const initialFormData = {title: '', description: '', phones: [], sendToAll: false};

const MESS_RESTRICTIONS = {
  title: 60,
  description: 270,
}

const CreateNotification = () => {
  const phonesRef = useRef(null);
  const ref = useRef(null);
  const [formData, setFormData] = React.useState(initialFormData);
  const [inputPhone, setInputPhone] = useState('');
  const [filteredAutocompList, setFilteredAutocompList] = useState([]);
  const [autocompleteList, setAutocompleteList] = useState([]);
  const [phonesIdMap, setPhonesIdMap] = useState({});
  const [modalMsg, setModalMsg] = useState('');
  const [loading, setLoading] = useState(true);
  const formattedMessage = useIntl().formatMessage;
  const disabledSending = !formData.title.length
    || !formData.description.length
    || (!formData.phones.length && !formData.sendToAll);

  const fetchPhones = async () => {
    const res = await RequestApi.getData(REACT_APP_API_URL_USERS_PHONES);
    const phoneDataArr = res?.['users_phones'];
    const newAutocompleteList = [];
    const newIdMap = {};
    if (Array.isArray(phoneDataArr)) {
      phoneDataArr.forEach((phoneData) => {
        const {id, phone: {formatted}} = phoneData;
        newIdMap[formatted] = id;
        newAutocompleteList.push(formatted);
      })
    }
    setLoading(false);
    setAutocompleteList(newAutocompleteList);
    setPhonesIdMap(newIdMap);
  };

  useEffect(() => {
    fetchPhones();
  }, []);

  useEffect(() => {
    const outsideHandler = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setFilteredAutocompList([]);
      }
    };
    document.addEventListener('mousedown', outsideHandler);
    return () => {
      document.removeEventListener('mousedown', outsideHandler);
    }
  }, [ref]);

  const onInput = (e) => {
    const {name, value} = e.target;

    return ['description', 'title'].includes(name) && MESS_RESTRICTIONS[name] >= value.length && setFormData({
      ...formData,
      [name]: value,
    });
  };

  const inputMaskKeyHandler = (event) => {
    const escKey = 27;
    const tabKey = 9;
    if (![escKey, tabKey].includes(event.keyCode)) {
      return;
    }
    event.preventDefault();
    event.stopPropagation();
    setInputPhone('');
    setFilteredAutocompList([]);
    phonesRef.current?.focus();
  }

  const phoneInputChange = (event) => {
    const val = event?.target?.value;
    setInputPhone(val);
    const matchObj = val.match(/^.*\d/);
    if (!matchObj) {
      return;
    }
    const value = matchObj[0];
    const filteredList =
      value ? autocompleteList.filter((num) => (num.startsWith(value) && !formData.phones.includes(num))) : [];
    setFilteredAutocompList(filteredList);
  }

  const deletePhone = (num) => {
    setFormData({...formData, phones: formData.phones.filter((p) => p !== num)});
  }

  const chooseAutocompleteHandler = (num) => {
    setFilteredAutocompList([]);
    if (typeof num !== 'string'
      || !num.match(/\+38 \(\d{3}\) \d{3}-\d{2}-\d{2}/)
      || formData.phones.indexOf(num) !== -1
    ) {
      return;
    }
    setFormData({...formData, sendToAll: false, phones: [...formData.phones, num]});
    setInputPhone('');
    phonesRef.current?.focus();
  }

  const checkboxChange = (ev) => {
    const {target: {checked}} = ev;
    return setFormData({...formData, phones: checked ? [] : formData.phones, sendToAll: checked});
  }

  const sendMessageHandler = () => {
    if (disabledSending) {
      return;
    }
    const {title, description: body, sendToAll: send_to_all, phones} = formData;
    const userIds = phones.reduce((ids, phone) => {
      const userId = phonesIdMap[phone];
      return userId ? [...ids, userId] : ids;
    }, []);

    const sendObj = {
      title,
      body,
      send_to_all,
      user_ids: userIds,
    };
    setLoading(true);

    RequestApi.postData(REACT_APP_API_URL_NOTIFICATIONS, JSON.stringify(sendObj))
      .then((res) => {
        const {errors, message = formattedMessage({id: 'create.notification.messSendSuccess'})} = res;
        if (errors) {
          const errorMess = errors?.[0]?.message || formattedMessage({id: 'create.notification.messSendError'});
          setModalMsg(errorMess);
          setLoading(false);
          return;
        }
        setModalMsg(message);
        setFormData(initialFormData);
        setLoading(false);
      });
  }

  return !loading ? (
    <>
      <HeaderMobile title={formattedMessage({id: 'create.notification.title'})} />
      <div className="desktop page-title text-bolder">
        <FormattedMessage id="create.notification.title" />
      </div>
      <form action="" className="notification-form">
        <div className="form-group">
          <div className="form-label">
            <span><FormattedMessage id="create.notification.messTitle" /></span>
            <span>{` (${MESS_RESTRICTIONS.title - formData.title.length} ${formattedMessage({id: 'create.notification.chars'})})`}</span>
          </div>
          <input
            name="title"
            placeholder={formattedMessage({id: 'create.notification.messPlaceholder'})}
            value={formData.title}
            className="form-control"
            onChange={onInput}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <span><FormattedMessage id="contact.modal.feedback.textarea.placeholder" /></span>
            <span>{` (${MESS_RESTRICTIONS.description - formData.description.length} ${formattedMessage({id: 'create.notification.chars'})})`}</span>
          </div>
          <textarea
            className={classNames('form-control', 'textarea-only-vertical')}
            placeholder={formattedMessage({id: 'contact.modal.feedback.textarea.placeholder'})}
            name="description"
            value={formData.description}
            rows="4"
            onChange={onInput}
          />
        </div>
        <div className="form-group">
          <div className="form-label">
            <FormattedMessage id="create.notification.phoneNums" />
          </div>
          <div className="form-control choose-container">
            <div className="input-phone-mask-wrapper" ref={ref}>
              <InputMask
                mask="+38 (999) 999-99-99"
                maskChar=" "
                name="phones"
                className="choose-input-mask"
                placeholder="+38 (000) 000-00-00"
                onKeyDown={inputMaskKeyHandler}
                onChange={phoneInputChange}
                value={inputPhone}
              />
              {!!filteredAutocompList.length && (
                <div className="drop-down-panel">
                  {filteredAutocompList
                    .map((num) => <PhoneItem phoneNumber={num}
                                             key={`phone-item-${num}`}
                                             chooseHandler={chooseAutocompleteHandler} />)}
                </div>
              )}
            </div>
            {formData.phones.map((num) => <PhoneTag key={`phone-tag-${num}`} deleteHandler={deletePhone} phoneNumber={num} />)}
          </div>
          <span tabIndex="0" ref={phonesRef} className="hidden-block" />
        </div>
        <div className="form-group">
          <label className="flex">
            <div className="form-label checkbox-label">
              <FormattedMessage id="create.notification.sendToAll" />
            </div>
            <input className="checkbox-input" type="checkbox" checked={formData.sendToAll}
                   onChange={checkboxChange} />
          </label>
        </div>
        <div className="form-group flex-end">
          <span className={classNames('btn', {'btn-disabled': disabledSending})}
                onClick={sendMessageHandler}
                disabled={disabledSending}
          >
              <FormattedMessage id="create.notification.send" />
          </span>
        </div>
      </form>
      <Modal
        open={!!modalMsg}
        onClose={() => {
          setModalMsg('')
        }}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-title -sub message-content">
          {modalMsg}
        </div>
      </Modal>
    </>
  ) : (
    <div className="text-center">
      <Loader />
    </div>
  );
};

export default CreateNotification;
