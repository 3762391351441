import React from 'react';

const Errors = (props) => {
  const { errors = {} } = props;

  const generateErrors = () => Object.keys(errors).map((key) => {
    const [error] = errors[key];

    return <div className="error">{error}</div>;
  });

  return generateErrors();
};

export default Errors;
