import React from 'react';

export const TOKEN_KEY = 'token';
export const NOTIFICATION_COUNTER = 'NOTIFICATION_COUNTER';
export const FIREBASE_TOKEN_KEY = 'firebase-token';
export const MONTHS = [
  'січня',
  'лютого',
  'березня',
  'квітня',
  'травня',
  'червня',
  'липня',
  'серпня',
  'вересня',
  'жовтня',
  'листопада',
  'грудня',
];

export const STATUS_ALIAS_BAR_WIDTH = {
  processing: 0,
  manufacturing: 33,
  in_warehouse: 66,
  shipping: 100,
  done: 100,
};
export const ORDER_STATUSES = ['error', 'success'];
export const MODAL_CLOSE_ICON = <span className="icon-times modal-close" />;

export const ORDER_STATUS_ALIASES = {
  MANUFACTURING: 'manufacturing',
}
