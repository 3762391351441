import React, {useMemo, useState} from 'react';
import SlideToggle from 'react-slide-toggle';
import {getDateFormatted, getFormattedDateShort, getOrderStatus, getStatusBarWidth} from 'utils/helpers';
import OrderDetails from './orderDetails';
import { FormattedMessage } from 'react-intl';
import { ORDER_STATUS_ALIASES } from "../../utils/const";
import 'pages/orders/styles.scss';

const { MANUFACTURING } = ORDER_STATUS_ALIASES;

const OrderItem = (props) => {
  const {
    id, code, name, payment_status, delivery, stages, declarationNumber,
  } = props;

  const [orderOpen, setOrderOpen] = useState(false);

  const statusesClassNames = {
    2: '-success',
    3: '-danger',
  };

  const [
    processingStage,
    manufacturingStage,
    warehouseStage,
    shippingStage,
    doneStage,
  ] = stages;

  const orderStatus = getOrderStatus(stages);
  const orderDone = useMemo(() => shippingStage.completed ? 'done' : '', [shippingStage]);
  return (
    <SlideToggle
      collapsed
      onExpanding={() => {
        setOrderOpen(true);
        if(window.matchMedia('(max-width: 767px)').matches) {
          document.querySelector('body').style.overflow = 'hidden';
        }
      }}
      onCollapsing={() => {
        if(window.matchMedia('(max-width: 767px)').matches) {
          document.querySelector('body').style.overflow = 'auto';
        }
      }}
      render={({ toggle, setCollapsibleElement, toggleState }) => (
        <div key={id} className="order-item">
          <div
            className={`order-header ${(toggleState || '').toLowerCase()}`}
            onClick={toggle}
          >
            <div className="order-code">{code}</div>
            <div className="order-name">{name}</div>
            <div className={`order-status ${orderStatus.alias}`}>
              <div className="mobile">
                <div className="order-mobile-name">{name}</div>
                <div className="status-block">
                  <div className="status-date">{getDateFormatted(processingStage.due_date)}</div>
                  <div className="status-bar">
                    <div className={`status-bar-progress ${orderDone}`} style={{ width: `${getStatusBarWidth(orderStatus.alias)}%` }} />
                    <div className="status-dots">
                      <div className={`status-dot active-${processingStage.completed || processingStage.current} ${orderDone}`} />
                      <div className={`status-dot active-${manufacturingStage.completed || manufacturingStage.current} ${orderDone}`} />
                      <div className={`status-dot active-${warehouseStage.completed || warehouseStage.current} ${orderDone}`} />
                      <div className={`status-dot active-${shippingStage.completed} ${orderDone}`} />
                    </div>
                  </div>
                  <div className="status-date">{!shippingStage.completed && getDateFormatted(doneStage.due_date)}</div>
                </div>
              </div>
              <span className={`${orderDone}-text`}>{orderStatus.name}</span>
            </div>
            <div className={`order-payment ${statusesClassNames[payment_status.status] || ''}`}>
              <div className="label mobile">
                <FormattedMessage id="orderItem.paymentStatusLabel" />
              </div>
              {payment_status.description}
            </div>
            <div className="order-delivery">
              <div className="label mobile">
                <FormattedMessage id="orderItem.deliveryTypeLabel" />
              </div>
              <div style={{display: 'flex', flexDirection: 'column'}}>
                <span>{delivery}</span>
                {declarationNumber
                  ? <span>№{declarationNumber}</span>
                  : null
                }
              </div>
            </div>
            <div className="order-date">
              {!shippingStage.completed && getFormattedDateShort(doneStage.due_date, orderStatus.alias !== MANUFACTURING)}
            </div>
          </div>
          <div className="order-content" ref={setCollapsibleElement}>
            { orderOpen && <OrderDetails id={id} onMobileClose={toggle} /> }
          </div>
        </div>
      )}
    />
  );
};

export default OrderItem;
