import { combineReducers } from 'redux';
import userReducer from './reducers/user';
import ordersReducer from './reducers/orders';
import layoutReducer from './reducers/layout';
import notificationCounterReducer from './reducers/notificationCounter';

const mainReducer = combineReducers({
  userStore: userReducer,
  ordersStore: ordersReducer,
  layoutStore: layoutReducer,
  notificationCounterStore: notificationCounterReducer,

});

export default mainReducer;
