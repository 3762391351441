import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import LocalStorage from 'services/LocalStorage';
import {FIREBASE_TOKEN_KEY, TOKEN_KEY} from 'utils/const';
import { FormattedMessage } from 'react-intl';
import '../styles.scss';
import RequestApi from '../../../services/RequestApi';
import {REACT_APP_API_URL_LOGOUT} from '../../../config';

const HeaderLogin = () => {
  const userData = useSelector((state) => state.userStore.userData);
  const history = useHistory();
  const logout = (e) => {
    e.preventDefault();
    RequestApi
      .postData(REACT_APP_API_URL_LOGOUT, null, {}, 'delete')
      .then(() => {})
      .catch(() => {})
    ;
    LocalStorage.remove(TOKEN_KEY);
    LocalStorage.remove(FIREBASE_TOKEN_KEY);
    history.push('/login');
  };

  if (!userData) {
    return null;
  }

  return (
    <div className="header-login">
      <FormattedMessage id="headerLogin.manager" />
      {' '}
      {userData.user.name}
      <span className="header-login-icon icon-user" />
      <div className="header-login-dropdown">
        <a href="#" onClick={logout} className="header-login-link">
          <FormattedMessage id="headerLogin.link" />
        </a>
      </div>
    </div>
  );
};

export default HeaderLogin;
