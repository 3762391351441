import React, { useEffect, useState } from 'react';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import Loader from 'components/loader';
import {getDateFormatted, getFormattedDateShort} from 'utils/helpers';
import RequestApi from 'services/RequestApi';
import { MODAL_CLOSE_ICON } from 'utils/const';
import imagePaymentSystems from 'assets/img/payment-systems.svg';
import { REACT_APP_API_URL_ORDERS } from '../../config';
import { FormattedMessage, useIntl } from 'react-intl';

const OrderDetails = (props) => {
  const { id, onMobileClose } = props;
  const formattedMessage = useIntl().formatMessage;
  const [orderDetails, setOrderDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [modalPayment, setOpenPayment] = useState(false);
  const [orderInvoice, setOrderInvoice] = useState(false);
  const [turnOnNotification, setTurnOnNotification] = useState(false);

  useEffect(() => {
    (async function () {
      const orderResponse = await RequestApi.getData(`${REACT_APP_API_URL_ORDERS}/${id}`);
      const { order = null } = orderResponse;

      if (!order) {
        console.error('Can not get order data');
        return;
      }

      setOrderDetails(order);
      setLoading(false);
    }());
  }, []);

  if (loading) {
    return <Loader />;
  }

  const normalizeDebtData = (debt) => {
    if (!debt || typeof debt.formatted !== 'string') {
      return false;
    }
    debt.formatted = debt.formatted.replace('-', '').trim();

    return debt.raw && debt.raw < 0;
  };

  const getInvoice = async () => {
    const invoiceRes = await RequestApi.getData(`${REACT_APP_API_URL_ORDERS}/${id}/invoice`);
    const { invoice } = invoiceRes;

    if (!invoice) {
      console.error('Can not get invoice data');
      return;
    }

    setOrderInvoice({
      bill_amount: invoice.bill_amount,
      checkout_url: invoice.checkout_url,
      description: invoice.description,
      encoding: invoice.encoding,
      expire_time: invoice.expire_time,
      failure_url: invoice.failure_url,
      lang: invoice.lang,
      payee_id: invoice.payee_id,
      shop_order_number: invoice.shop_order_number,
      success_url: invoice.success_url,
    });
    setOpenPayment(true);
  };

  const {
    code,
    name,
    delivery,
    declaration_number: declarationNumber,
    stages = [],
    company_name: companyName,
    contact_phone: companyPhone,
    total,
    debt,
    payment_status,
    overdue_days: overdueDays,
    operator_name: operatorName,
    operator_phone: { formatted: formattedPhoneNumber, raw: rawPhoneNumber },
    user,
    can_be_payed: canBePayed,
  } = orderDetails;

  const [
    processingStage,
    manufacturingStage,
    warehouseStage,
    shippingStage,
    doneStage,
  ] = stages;

  const manufacturingProgress = [];

  if (stages.length) {
    for (let i = 0; i < manufacturingStage.progress.from; i++) {
      manufacturingProgress.push(<span className="order-content-progress-item-dot" key={i} />);
    }

    for (let j = 0; j < manufacturingStage.progress.done; j++) {
      manufacturingProgress[j] = <span className="order-content-progress-item-dot active" key={j} />;
    }
  }

  const calculateStageProgressStyles = (stage) => {
    if (stage.current) {
      return {
        backgroundImage: `conic-gradient(#C29B10 ${100 / stage.progress.from * stage.progress.done}%, white 25%)`,
      };
    }
    if (stage.completed) {
      return {
        backgroundColor: '#021610',
        opacity: 0.5,
      }
    }
    return {};
  }

  const showPlanningReady = () => {
    return !manufacturingStage.completed;
  }

  const isPrepayment = normalizeDebtData(debt);

  return (
    <>
      <div className="mobile">
        <div className="order-content-mobile-header">
          <div className="mobile-title">
            <FormattedMessage id="orderDetails.title" />
          </div>
          <div className="order-content-mobile-close" onClick={onMobileClose}>
            <span className="icon-times" />
          </div>
        </div>
        <div className="order-code order-code-mobile">
          {code}
          <span className="icon-copy" onClick={() => {
            navigator.clipboard.writeText(code);
            setTurnOnNotification(true);
            setTimeout(() => setTurnOnNotification(false), 1000);
          }}/>
        </div>
      </div>
      {turnOnNotification && (
        <div style={{
          backgroundColor: 'floralwhite',
          position: 'absolute',
          top: 0,
          right: 0,
          justifyContent: 'center',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          width: '230px',
          color: 'green',
          height: '35px',
          zIndex: 100,
        }}>
          <p>
            <FormattedMessage id="orderDetails.copyCode" />
          </p>
        </div>
      )}
      { name && (
          <div className="order-content-item">
            <div className="label -big">
              <FormattedMessage id="orderDetails.orderNameLabel" />
            </div>
            <div className="value -big-mobile">{name}</div>
          </div>
      )}
      <div className="order-detail-block">
        <div className="order-content-progress">
          {showPlanningReady() && (
            <div className="order-content-progress-item flex">
              <div className="order-content-progress-item-icon" style={{ backgroundColor: '#021610', opacity: 0.5 }} />
              <div className='label darked -big'>
                <span>{doneStage.name}</span>
              </div>
              <div className='label darked -big'>
                {getDateFormatted(doneStage.due_date, false)}
              </div>
            </div>
          )}
          <div className={`order-content-progress-item flex ${shippingStage.current ? '-large' : ''}`}>
            <div className="order-content-progress-item-icon" style={calculateStageProgressStyles(shippingStage)} />
            <div className={`${shippingStage.current ? 'active' : 'label'} ${shippingStage.completed ? 'darked' : ''} -big`}>
              <span>{shippingStage.name}</span>
            </div>
            <div className={`${shippingStage.current ? 'active' : 'label'} ${shippingStage.completed ? 'darked' : ''} -big`}>
              {shippingStage.due_date && getFormattedDateShort(shippingStage.due_date, false)}
            </div>
          </div>
          <div className={`order-content-progress-item flex ${warehouseStage.current ? '-large' : ''}`}>
            <div className="order-content-progress-item-icon" style={calculateStageProgressStyles(warehouseStage)} />
            <div className={`${warehouseStage.current ? 'active' : 'label'} ${warehouseStage.completed ? 'darked' : ''} -big`}>
              <span>{warehouseStage.name}</span>
            </div>
            <div className={`${warehouseStage.current ? 'active' : 'label'} ${warehouseStage.completed ? 'darked' : ''} -big`}>
              {warehouseStage.due_date && getFormattedDateShort(warehouseStage.due_date, true)}
            </div>
          </div>
          <div className={`order-content-progress-item flex ${manufacturingStage.current ? '-large' : ''}`}>
            <div className="order-content-progress-item-icon" style={calculateStageProgressStyles(manufacturingStage)} />
            <div className={`${manufacturingStage.current ? 'active' : 'label'} ${manufacturingStage.completed ? 'darked' : ''} -big`}>
              {manufacturingStage.name}
              {manufacturingStage.current && manufacturingProgress}
            </div>
            <div className={`${manufacturingStage.current ? 'active' : 'label'} ${manufacturingStage.completed ? 'darked' : ''} -big`}>
              {(manufacturingStage.name === 'Изготовлен' && manufacturingStage.due_date) && getFormattedDateShort(manufacturingStage.due_date, true)}
            </div>
          </div>
          <div className={`order-content-progress-item flex ${processingStage.current ? '-large' : ''}`}>
            <div className="order-content-progress-item-icon" style={calculateStageProgressStyles(processingStage)} />
            <div className={`${processingStage.current ? 'active' : 'label '} ${processingStage.completed ? 'darked' : ''} -big`}>
              {processingStage.name}
            </div>
            <div className={`${processingStage.current ? 'active' : 'label '} ${processingStage.completed ? 'darked' : ''} -big`}>
              {processingStage.due_date && getFormattedDateShort(processingStage.due_date, false)}
            </div>
          </div>
        </div>
        { canBePayed && (
            <div className="order-content-pay">
              <div className="label">
                <FormattedMessage id="orderDetails.label" />
              </div>
              <div>
                <button className="btn -full" onClick={getInvoice}>
                  <FormattedMessage id="orderDetails.payDebt" />
                </button>
              </div>
            </div>
        )}
        <div className="order-content-sum">
          {total && (
              <div className="order-content-item">
                <div className="label -big">
                  <FormattedMessage id="orderDetails.priceLabel" />
                </div>
                <div className="value">{total.formatted}</div>
              </div>
          )}
          <div className="order-content-item">
            <div className="label -big">
              {Boolean(debt.raw <= 0)  && (
                <FormattedMessage id={"orderDetails.prepayment"} />
              )}
              {Boolean(debt.raw > 0)  && (
                <FormattedMessage id={"orderDetails.debtLabel"} />
              )}
            </div>
            <div className="flex order-debt">
              <div className="value">{debt.formatted.replace('-', '')}</div>
              { overdueDays > 0 && !isPrepayment &&  (
              <div className="value -small -danger">
                <FormattedMessage id="orderDetails.delayDateLabel" />
                {' '}
                {overdueDays}
                {' '}
                <FormattedMessage id="days" />
                {' '}
              </div>
              ) }
            </div>
          </div>
        </div>

      </div>

      <div className="order-content-delivery">
        <div className="label">
          <FormattedMessage id="orderDetails.deliveryInfoLabel" />
        </div>
        <div className="order-content-delivery-block">
          <div className="order-content-delivery-block-content">
            <div className="order-content-item">
              <div className="label -big">
                <FormattedMessage id="orderDetails.deliveryLabel" />
              </div>
              <div className="value">{delivery}</div>
            </div>
            {declarationNumber && (
                <div className="order-content-item">
                  <div className="label -big">
                    <FormattedMessage id="orderDetails.declarationNumberLabel" />
                  </div>
                  <div className="value">{declarationNumber}</div>
                </div>
            ) }

            <div className="order-content-item">
              <div className="label -big">
                <FormattedMessage id="orderDetails.senderLabel" />
              </div>
              <div className="value">{companyName}</div>
              <div>
                <a href={"tel:" + companyPhone} className="link-phone">{companyPhone}</a>
              </div>
            </div>
            <div className="order-content-item">
              <div className="label -big">
                <FormattedMessage id="orderDetails.managerLabel" />
              </div>
              <div className="value">{operatorName}</div>
              <div>
                <a href={"tel:" + rawPhoneNumber} className="link-phone">
                  {formattedPhoneNumber}
                </a>
              </div>
            </div>
          </div>
          <div className="order-content-delivery-manager">
            <div className="order-content-item">
              <div className="label -big">
                <FormattedMessage id="orderDetails.clientManagerLabel" />
              </div>
              <div className="value">{user.name}</div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={modalPayment}
        onClose={() => setOpenPayment(false)}
        center
        closeIcon={MODAL_CLOSE_ICON}
        classNames={{
          overlay: 'modal-overlay',
          modal: 'modal-default',
        }}
      >
        <div className="modal-group">
          <div className="modal-title -sub">
            <FormattedMessage id="orderDetails.modal.title" />
          </div>
          <div className="modal-title">{code}</div>
        </div>
        <div className="modal-group">
          <div className="modal-title -sub">
            <FormattedMessage id="orderDetails.modal.text" />
          </div>
          <div className="modal-title">{debt.formatted}</div>
        </div>
        <div className="modal-group -big text-center">
          <form action={orderInvoice.checkout_url} method="post">
            <input type="hidden" name="payee_id" value={orderInvoice.payee_id} />
            <input type="hidden" name="shop_order_number" value={orderInvoice.shop_order_number} />
            <input type="hidden" name="bill_amount" value={orderInvoice.bill_amount} />
            <input type="hidden" name="description" value={orderInvoice.description} />
            <input type="hidden" name="success_url" value={orderInvoice.success_url} />
            <input type="hidden" name="failure_url" value={orderInvoice.failure_url} />
            <input type="hidden" name="lang" value={orderInvoice.lang} />
            <input type="hidden" name="encoding" value={orderInvoice.encoding} />
            <input type="hidden" name="exp_time" value={orderInvoice.expire_time} />
            <button className="btn" type="submit">
              <FormattedMessage id="orderDetails.modal.accept" />
            </button>
          </form>
        </div>
        <div className="modal-group text-center">
          <a href="/terms" className="link-phone">
            <FormattedMessage id="orderDetails.modal.paymentTerms" />
          </a>
        </div>
        <div className="text-center">
          <img src={imagePaymentSystems} alt={formattedMessage({ id: 'orderDetails.paymentSystemImg.alt' })} />
        </div>
      </Modal>
    </>
  );
};

export default OrderDetails;
