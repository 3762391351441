import React, { useEffect, useState, useCallback } from 'react';
import ReactPaginate from 'react-paginate';
import RequestApi from 'services/RequestApi';
import UsersList from 'components/users/users';
import Loader from 'components/loader';
import { REACT_APP_API_URL_USERS } from '../../config';
import './styles.scss';

const Users = () => {
  const [loading, setLoading] = useState(false);
  const [userList, setUserList] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const [forcePage, setForcePage] = useState(0);

  const fetchUsers = async (page) => {
    setForcePage(page.selected);
    const pageToGo = page.selected + 1;
    setLoading(true);

    const resUsers = await RequestApi.getData(`${REACT_APP_API_URL_USERS}?page=${pageToGo}&per_page=${process.env.REACT_APP_DATA_PER_PAGE}`);
    const { users = null, total } = resUsers;
    if (!users) {
      console.error('Can not get users list');
      return;
    }
    setPageCount(total / process.env.REACT_APP_DATA_PER_PAGE);
    setUserList(users);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers({ selected: 0 });
  }, []);

  const changeUserList = useCallback(() => {
    fetchUsers({ selected: 0 });
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <UsersList users={userList} onChangeUserList={changeUserList} />
      {
        pageCount > 1 && (
            <ReactPaginate
              previousLabel="<"
              nextLabel=">"
              breakLabel="..."
              breakClassName="break-me"
              pageCount={pageCount}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={fetchUsers}
              containerClassName="pagination"
              subContainerClassName="pages pagination"
              pageLinkClassName="page-link"
              activeClassName="active"
              forcePage={forcePage}
            />
          )
      }

    </>
  );
};

export default Users;
