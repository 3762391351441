import React from 'react';
import './styles.scss';

const ListLinks = ({ list }) => (
  <ul className="list-links">
    {
          list.map(({ id, title, link }) => (
            <li className="list-links-item" key={id}>
              <a href={link} className="link-phone">{title}</a>
            </li>
          ))
        }
  </ul>
);

export default ListLinks;
